'use strict';

var o = require(6);

var ga = {};

ga[o.DELIVERY_AND_STOCK_POSITION] = 6;
ga[o.REMOTE_STOCK] = 8;
ga[o.TRUSTPILOT_CHECKOUT] = 5;
ga[o.LOW_STOCK_NEW] = 7;

module.exports = ga;
