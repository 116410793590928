'use strict';

/**
 * Smooth scrolling to links on same page
 * https://css-tricks.com/snippets/jquery/smooth-scrolling/
 * dependency: jQuery
 *
 * @TODO: don't trigger smooth scroll on modal links
 */
function smoothScroll() {
    $('a[href^="#"]:not([href$="#"]):not([href*="/"]):not([data-modal])').on('click', function() {
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
            location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - 40 // adjust for fixed header on mobile
                }, 500);
                return false;
            }
        }
    });
}

module.exports = smoothScroll;
