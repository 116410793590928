'use strict';

/**
 * Legacy scripts - queries all product tiles on page and formats price, adjust stock and delivery info
 * and adds class for outlet products to display outlet badge
 * @TODO: refactor to optimize performance and reduce DOM manipulation (see also stock-info)
 */

var CNET = require(78);

var formatter = require(64);
var stockInfo = require(23);

var updateProductTiles = function() {

    var products = $('.Product:not(.updated)');

    products.each(function(index, prod) {

        var $prod = $(prod);
        var productNumber = $prod.find('.ProductNumber').text().trim();
        var productPriceNode = $prod.find('.js-productPrice');
        var manufacturer = $prod.find('.ProductManufactors').text();
        var vendorNumber = $prod.find('.VendorNumber').text();
        var productPrice = productPriceNode.text().trim();
        var specialOfferPriceNode = $prod.find('.js-specialOfferPrice');
        var specialOfferPrice = specialOfferPriceNode.text().trim();
        var newClass = [];
        var newPrice = '';

        // update stock and delivery info on tile
        stockInfo.update(prod);

        // Adding class for outlet product
        if (productNumber && productNumber.indexOf('B-') !== -1) {
            newClass.push('is-outlet');
        }

        // Check for special offer class
        if (specialOfferPrice.length > 1) {
            if (specialOfferPrice.indexOf(',') !== -1) {
                newPrice = specialOfferPrice.replace(',00', ',-');
            } else {
                var tmpPrice = formatter(Number(specialOfferPrice));
                newPrice = tmpPrice.replace(',00', ',-');
            }
            specialOfferPriceNode.html(newPrice.trim()); // strip whitespace added in CMS
            specialOfferPriceNode.removeClass('hide');
        } else {
            // Check for 'Call for price' case
            if (productPrice === '0,00' || productPrice.indexOf('Ring') !== -1) {
                newPrice = 'Ring for pris!';
                newClass.push('ring');
            // Check if price outputs both regular and special offer price
            } else if (productPrice.match(/\s/)) {
                newPrice = productPrice.split(/\s/).pop().replace(',00', ',-');
            } else {
                newPrice = productPrice.replace(',00', ',-');
            }
            productPriceNode.html(newPrice);
            productPriceNode.removeClass('hide');
        }

        CNET.appendEnergyLogo(manufacturer, vendorNumber, $prod);

        newClass.push('updated');
        $prod.addClass(newClass.join(' '));
    });

};

/* test-code */
// export globally for use in react
window.updateProductTiles = updateProductTiles;
/* end-test-code */

module.exports = {
    update: updateProductTiles
};
