'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var formatter = require('../util/formatters/currency');

/**
 * Parses data from product tile
 */

exports.parseProduct = function(context) {

    var result = {};

    // Name and number
    result.name = context.find('.ProdInfoText a:last-child').text().trim();
    result.number = context.find('.ProductNumber:first').text().trim();

    // Price, quantity and stock
    result.price = formatter(context.find('.Price span:not(.hide)').text().trim());
    result.quantity = 1;
    result.stock = context.find('.js-stockValList').text().trim();
    if (!result.stock) {
        result.stock = 0;
    }

    // Manufator
    result.manufactor = context.find('.ProductManufactors').text().trim();
    if (!result.manufactor) {
        result.manufactor = undefined;
    }

    // Categories
    result.categories = [];


    var breadCrumb = $('.BreadCrumb_ProductInfo');
    if (!breadCrumb.length) {
        breadCrumb = $('.SubCats_BreadCrumb');
    }

    breadCrumb.find('.BreadCrumbLink').each(function() {
        result.categories.push($(this).text().trim());
    });
    var activeCategory = breadCrumb.find('.BreadCrumbLink_Active').text().trim();
    if (activeCategory.length > 0) {
        result.categories.push(activeCategory);
        result.category = activeCategory;
    }

    if (!result.category && window.location.pathname.indexOf('frontpage')) {
        result.category = 'Frontpage';
    }

    // Position
    result.position = context.parent('form').parent().index() + 1;

    // URL
    // ternary operator needed because locally links are rendered using react-router so they
    // can be undefined at time of execution
    result.link = context.find('.ProductLink a')[0] ? context.find('.ProductLink a')[0].href : null;

    return result;
};
