'use strict';

var R = require('../../helpers/_rhythm');

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

require('vendor/isInViewport');

var product = require('modules/parsers/product');
var list = require('modules/parsers/list');
var context = require('modules/parsers/context');
var step4 = require('modules/parsers/checkout-step4');
var category = require('modules/parsers/category');
var profit = require('modules/parsers/profit');

var adWordsFixtures = require('../../../../configs/adwords.js');

// Loading required modules
var uaManager = require('modules/controllers/universalAnalyticsManager');
var basket = require('modules/models/basket');
var parser = {
    product: product,
    list: list,
    context: context,
    step4: step4,
    category: category,
    profit: profit
};

var network = {};
var networkResponse = new Event('networkResponse');

function _getTileContext() {
    switch (R.getActiveSection()) {
        case R.SECTIONS.FRONTPAGE:
            return 'Frontpage';
        case R.SECTIONS.SEARCH:
            return 'Search';
        case R.SECTIONS.PRODUCT_LIST:
            return parser.context();
        case R.SECTIONS.PRODUCT_PAGE:
            return 'Product page accessories';
        case R.SECTIONS.BASKET:
            return 'Basket accessories';
        default:
            return 'Unknown';
    }
}

function _submitProductTileImpression() {
    $('.product:not(.viewed):in-viewport').each(function() {
        var product = parser.list.parseProduct($(this));
        uaManager.productImpression(product, _getTileContext());
    }).addClass('viewed');
}


function _addProductTileImpressionListener() {
    setInterval(function() {
        if (($('.product:not(.viewed):in-viewport').length)) {
            _submitProductTileImpression();
        }
    }, 250);
}

function _addProductTileClickListener() {
    $(document).on('click', '.product', function(e) {
        var $parent = $(e.target).parent();
        var $product = $(e.target).closest('.product');
        var product = parser.list.parseProduct($product);
        if ($parent.is('.BuyButton')) {
            uaManager.productAddedToCart(product, _getTileContext());
        } else {
            uaManager.productClick(product, _getTileContext());
        }
    });
}

function _submitProductDetails() {
    var $productPage = $('.ProductPage');
    var product = parser.product.parseProduct($productPage);
    // parsing product profit from the page just once since parser deletes the node
    product.pfp = parser.profit.parseProfit($productPage);
    _adWordsRemarketing(product.number, 'product', product.price, parser.category(product));
    uaManager.productDetails(product);
    if (product.imageType === 'Gallery') {
        ga('send', {
            hitType: 'event',
            eventCategory: 'Gallery',
            eventAction: 'Load',
            eventLabel: $('.js-GalleryCounter .total')[0].textContent,
            nonInteraction: true
        });
    }
}

/**
 * networkResponse event is dispatched twice: once after GA page view event and once after cnet ajax response.
 * These events are in race so we don't know which will finish first but we need both to finish before submitting product details
 */
function _addProductDetailsListener() {
    document.addEventListener('networkResponse', function(e) {
        if (network && network.cnet && network.ga) {
            _submitProductDetails();
        }
    }, false);
}


function _addProductPageAddToCartListener() {
    var list = 'Product page';
    $('.buyWrapper').on('click', function(event) {
        var $productPage = $('.ProductPage');
        var product = parser.product.parseProduct($productPage);
        uaManager.productAddedToCart(product, list);
    });
}

/**
 * Populate google_custom_params object with relevant data and sumbit for adwords remarketing
 * @param  {string} prodid product id
 * @param  {string} pagetype - product, checkout or purchase
 * @param  {number} totalValue - sum value of all products in basket
 * @param  {string} product category
 */
function _adWordsRemarketing(prodid, pagetype, totalValue, category) {

    var adWordsData = adWordsFixtures.remarketing;
    adWordsData.google_custom_params = {};
    adWordsData.google_custom_params.ecomm_prodid = prodid;
    adWordsData.google_custom_params.ecomm_pagetype = pagetype;

    if (totalValue) {
        adWordsData.google_custom_params.ecomm_totalvalue = totalValue;
    }

    if (category) {
        adWordsData.google_custom_params.ecomm_category = category;
    }

    if (window.google_trackConversion) {
        window.google_trackConversion(adWordsData);
    } else {
        console.error('Not able to send adwords tracking data.');
    }
}

/**
 * Second adwords call for conversion tracking, used only on confirmation page
 */
function _conversionTracking() {

    var conversionData = adWordsFixtures.conversions;

    if (window.google_conversion_value) {
        conversionData.google_conversion_value = window.google_conversion_value;
    }

    if (window.google_trackConversion) {
        window.google_trackConversion(conversionData);
    } else {
        console.error('Not able to send adwords tracking data.');
    }
}

function _getProductIds(products) {

    if (products && products.length === 1) {
        return products[0];
    }

    var productIds = [];
    for (var i = 0; i < products.length; i++) {
        var prod = products[i];
        productIds.push(prod.number);
    }
    return productIds;
}

function _getTotalValue(products) {
    var sumValue = 0;
    for (var i = 0; i < products.length; i++) {
        var prod = products[i];
        sumValue = sumValue + (prod.price * parseInt(prod.quantity));
    }
    return sumValue;
}

// Add product impression and click to product list, product page and frontpage
R(R.EVENTS.POST_LOAD, [R.SECTIONS.FRONTPAGE, R.SECTIONS.PRODUCT_LIST, R.SECTIONS.SEARCH], function() {
    _addProductTileClickListener();
    uaManager.init().then(function() {
        _submitProductTileImpression();
        _addProductTileImpressionListener();
        uaManager.pageLoaded();
    });
});

R(R.EVENTS.POST_LOAD, R.SECTIONS.PRODUCT_PAGE, function() {
    _addProductDetailsListener();
    _addProductTileClickListener();
    _addProductPageAddToCartListener();
    uaManager.init().then(function() {
        _submitProductTileImpression();
        _addProductTileImpressionListener();
        uaManager.pageLoaded();
        network.ga = true;
        document.dispatchEvent(networkResponse);
    });
});

//Add product details to product page
R(R.EVENTS.PRODUCT_PAGE_LOAD, R.SECTIONS.PRODUCT_PAGE, function() {
    network.cnet = true;
    document.dispatchEvent(networkResponse);
});

//Submit basket content and basket step
R(R.EVENTS.POST_LOAD, R.SECTIONS.BASKET, function() {
    basket.addEventListener(function(data) {
        var products = data.items;
        _addProductTileClickListener();
        _adWordsRemarketing(_getProductIds(products), 'cart', _getTotalValue(products));
        uaManager.init().then(function() {
            _submitProductTileImpression();
            _addProductTileImpressionListener();
            uaManager.checkoutStep(products, { 'step': 1 });
            uaManager.pageLoaded();
        });
    });
});

//Submit basket content and contact details
R(R.EVENTS.POST_LOAD, R.SECTIONS.DETAILS, function() {
    basket.addEventListener(function(data) {
        var products = data.items;
        _adWordsRemarketing(_getProductIds(products), 'cart', _getTotalValue(products));
        uaManager.init().then(function() {
            uaManager.checkoutStep(products, { 'step': 2 });
            uaManager.pageLoaded();
        });
    });
});

//Submit basket content and contact details
R(R.EVENTS.POST_LOAD, R.SECTIONS.SHIPMENT_PAYMENT, function() {
    basket.addEventListener(function(data) {
        var products = data.items;
        _adWordsRemarketing(_getProductIds(products), 'cart', _getTotalValue(products));
        uaManager.init().then(function() {
            uaManager.checkoutStep(products, { 'step': 3 });
            uaManager.pageLoaded();
        });
    });
});

//Submit basket content and contact details
R(R.EVENTS.POST_LOAD, R.SECTIONS.APPROVE, function() {
    basket.addEventListener(function(data) {
        var products = data.items;
        _adWordsRemarketing(_getProductIds(products), 'cart', _getTotalValue(products));
        uaManager.init().then(function() {
            uaManager.checkoutStep(products, { 'step': 4 });
            uaManager.pageLoaded();
        });
    });
});

//Submit basket content and contact details
R(R.EVENTS.POST_LOAD, R.SECTIONS.CONFIRMATION, function() {
    var products = parser.step4.parseOrderLines($('body'));
    var orderDetails = parser.step4.parseOrderDetails();
    _adWordsRemarketing(_getProductIds(products), 'purchase', _getTotalValue(products));
    _conversionTracking();
    uaManager.init().then(function() {
        uaManager.checkoutConfirmation(products, orderDetails);
        uaManager.pageLoaded();
        if (window.localStorage) {
            localStorage.clear();
        }
    });
});

// Add product impression and click to frontpage
R(R.EVENTS.POST_LOAD, R.SECTIONS.EXTERNAL, function() {
    uaManager.init().then(function() {
        uaManager.pageLoaded();
    });
});

// Add cache impressions on unload
R(R.EVENTS.UNLOAD, R.SECTIONS.GLOBAL, function() {
    uaManager.browserReloading();
});




