'use strict';

var serviceConfig = require(7);

function hotjar() {

    window.hj=window.hj||function(){(window.hj.q=window.hj.q||[]).push(arguments);};

    // don't load on dev and staging
    if (location.host.indexOf('cinemagic') === -1) {
        return;
    }

    window._hjSettings = serviceConfig.hotjar;

    var hotjarSrc = '//static.hotjar.com/c/hotjar-' + window._hjSettings.hjid + '.js?sv=' + window._hjSettings.hjsv;
    window.loadJS(hotjarSrc);

}

module.exports = hotjar;
