'use strict';

var modal = require(18);

var serviceConfig = require(7);
var serialize = require(66);

var mq = window.mq;

/**
 * Returns path to the image when requested via ImgIX with compression paramenters
 * For more info about query parameters see: https://docs.imgix.com/apis/url
 * @param  {string} absolute path to the image
 * @return {string} path to the image on imgix cdn
 */
function returnImgIXURL(imagePath) {

    var imgix = serviceConfig.imgix;

    var settings = {
        w: 540,
        auto: 'format,compress',
        fit: 'max',
        lossless: true
    };

    //extract pathname only
    var tmp = document.createElement('a');
    tmp.href = imagePath;
    var pathname = tmp.pathname;
    if(pathname.charAt(0).localeCompare("/") !== 0){
        pathname = "/" + pathname;
    }
    return imgix + pathname + '?' + serialize(settings);
}

function buildGallery(images) {

    var galleryMain = $('.js-GalleryMain');
    var galleryThumbs = $('.js-GalleryThumbs');
    var galleryModal = $('.js-GalleryModal');

    // clear whitespaces
    galleryMain.html('');
    galleryThumbs.html('');
    galleryModal.html('');

    images.forEach(function(img, index) {

        var mainImg = buildImg(returnImgIXURL(img.full), false);

        // build modal and thumbnails only on non-mobile screens
        if (!mq('tiny')) {
            mainImg.dataset.modal = '#gallery-modal';
            var thumbImg = buildImg(img.p75);
            var modalImg = buildImg(img.full, true);
            galleryModal.append(modalImg);
            galleryThumbs.append(thumbImg);
        }

        galleryMain.append(mainImg);

    });

    if (!mq('tiny')) {
        buildGalleryModal();
    }
    buildGalleryCounter(images.length);
    loadSlider(galleryMain, galleryThumbs);

}

function moveEnergyRating() {
    var energy = $('.js-energyRating');
    energy.addClass('has-gallery').detach();
    $('.js-GalleryMain').append(energy);
}

function buildImg(img, lazyLoad) {
    var wrapper = document.createElement('div');
    var imageEl = document.createElement('img');
    if (lazyLoad) {
        imageEl.classList.add('lazyOwl');
        imageEl.dataset.src = img;
    } else {
        imageEl.src = img;
    }
    imageEl.alt = '';
    wrapper.appendChild(imageEl);
    return wrapper;
}

function buildGalleryCounter(total) {
    var counter = $('.js-GalleryCounter');
    counter.find('.current').html('1');
    counter.find('.total').html(total);
}

function buildGalleryModal() {
    var counter = $('.js-GalleryCounter').clone();
    $('.js-GalleryModal').parent().prepend(counter);
    // init modal
    modal();
}

// using owl carousel for responsive slider: http://owlgraphic.com/owlcarousel/
// @TODO: investigate custom-made gallery or alternative library (ie. http://photoswipe.com/)
function loadSlider(galleryMain, galleryThumbs) {
    window.loadJS('https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.min.js',
        document.getElementById('loadjs'),
        function() {
            initSlider(galleryMain, galleryThumbs);
            moveEnergyRating();
        });
}

function initSlider(galleryMain, galleryThumbs) {

    var modalGalleryMain = $('.js-GalleryModal');
    var modalInit = false;

    var mainDefaults = {
        singleItem: true,
        navigation: true,
        navigationText: false,
        pagination: false,
        lazyLoad: true,
        lazyFollow: true,
        lazyEffect: false,
        transitionStyle: 'fade',
        rewindNav: true,
        rewindSpeed : 300,
        addClassActive: true,
        mouseDrag: false,
        afterMove: function(data) {
            updateGalleryCounter(data);
        }
    };

    var thumbsSliderSettings = {
        itemsDesktop: false,
        itemsDesktopSmall: false,
        itemsTablet: false,
        itemsMobile: false,
        pagination: false,
        navigation: true,
        navigationText: false,
        rewindNav: true,
        rewindSpeed : 300,
        mouseDrag: false,
        scrollPerPage : true,
        afterInit: function(el) {
            el.find('.owl-item').eq(0).addClass('synced');
        },
        afterMove: function(el) {
            var current = this.visibleItems;
            ga('send', {
                hitType: 'event',
                eventCategory: 'Gallery',
                eventAction: 'ThumbnailPan',
                eventLabel: current[0].toString()
            });
        }
    };

    var mainSliderSettings = {};

    $.extend(mainSliderSettings, mainDefaults, {
        lazyLoad: false,
        afterAction: function(el) {
            var current = this.currentItem;
            syncPosition(this, galleryThumbs);
        }
    });

    galleryMain.owlCarousel(mainSliderSettings);
    if (!mq('tiny')) {
        galleryThumbs.owlCarousel(thumbsSliderSettings);
    }

    $('.owl-prev').addClass('icon-chevron-left');
    $('.owl-next').addClass('icon-chevron-right');

    function updateGalleryCounter(data) {
        var current = $(data[0]).find('.owl-item.active').index();
        $('.js-GalleryCounter').find('.current').html(current + 1);
    }

    // make sure main image, modal image and thumbnail image are all same when navigating
    function syncPosition(data, Thumbs) {
        if (mq('tiny')) {
            return;
        }
        var current = data.currentItem;
        Thumbs
            .find('.owl-item')
            .removeClass('synced')
            .eq(current)
            .addClass('synced');
        if (Thumbs.data('owlCarousel') !== undefined) {
            center(Thumbs, current);
        }
    }

    function center(Thumbs, number) {
        var visible = Thumbs.data('owlCarousel').owl.visibleItems;
        var selectedIsVisible = ($.inArray(number, visible) !== -1) ? true : false;
        if (!selectedIsVisible) {
            Thumbs.trigger('owl.goTo', number);
        }
    }

    // Google Analytics event tracking
    function trackPanning(pos) {
        var modal = $('.c-gallery--modal').hasClass('is-open');
        ga('send', {
            hitType: 'event',
            eventCategory: 'Gallery',
            eventAction: modal ? 'ZoomPan' : 'Pan',
            eventLabel: pos.toString()
        });
    }

    galleryThumbs.on('click', '.owl-item', function(e) {
        e.preventDefault();
        var number = $(this).data('owlItem');
        galleryMain.trigger('owl.jumpTo', number);
        ga('send', {
            hitType: 'event',
            eventCategory: 'Gallery',
            eventAction: 'ThumbnailPress',
            eventLabel: number.toString()
        });

    });

    // handle navigation with cursor keys
    $(document.documentElement).on('keydown', function(event) {
        var owl = galleryMain.data('owlCarousel');
        var owl2 = modalGalleryMain.data('owlCarousel');
        if (event.keyCode === 37) {
            owl.prev();
            owl2.prev();
            trackPanning(owl.owl.currentItem);
        } else if (event.keyCode === 39) {
            owl.next();
            owl2.next();
            trackPanning(owl.owl.currentItem);
        }
    });

    // sync current image after closing modal and track GA event
    $('#gallery-modal .js-closeModal').on('click', function(e) {
        var current = modalGalleryMain.data('owlCarousel').owl.currentItem;
        galleryMain.data('owlCarousel').jumpTo(current);
        ga('send', {
            hitType: 'event',
            eventCategory: 'Gallery',
            eventAction: 'ZoomClose',
            eventLabel: current.toString()
        });
    });

    $('.js-GalleryMain [data-modal]').on('click', function(e) {
        if (!modalInit) {
            modalGalleryMain.owlCarousel(mainDefaults);
            $('.owl-prev').addClass('icon-chevron-left');
            $('.owl-next').addClass('icon-chevron-right');
            modalInit = true;
        }
        modalGalleryMain.trigger('owl.jumpTo', galleryMain.data('owlCarousel').currentItem);
        ga('send', {
            hitType: 'event',
            eventCategory: 'Gallery',
            eventAction: 'ZoomOpen',
            eventLabel: galleryMain.data('owlCarousel').currentItem.toString()
        });
    });

    // handle navigation using arrows
    $('.js-GalleryMain .owl-prev, .js-GalleryMain .owl-next, .js-modalGalleryMain .owl-prev, .js-modalGalleryMain .owl-next')
        .on('click', function(e) {
            var pos = $(e.target).closest('.owl-carousel').data('owlCarousel').currentItem;
            trackPanning(pos);
        });

}

module.exports = {
    buildGallery: buildGallery
};
