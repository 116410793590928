'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

var SECTIONS = {
    GLOBAL: 'Global',
    FRONTPAGE: 'Frontpage',
    PRODUCT_LIST: 'Product List',
    PRODUCT_PAGE: 'Product Page',
    SEARCH: 'Search',
    BASKET: 'Basket',
    DETAILS: 'Step 1',
    SHIPMENT_PAYMENT: 'Step 2',
    APPROVE: 'Step 3',
    CONFIRMATION: 'Confirmation',
    EXTERNAL: 'External',
    CREDIT_CARD: 'Credit Card',
    B2B: 'B2B'
};

var EVENTS = {
    LOAD: 'load',
    RENDER: 'render',
    UNLOAD: 'unload',
    PRODUCT_PAGE_LOAD: 'product-page-load',
    POST_LOAD: 'post-load'
};

var functions;

/**
 * Returns name of current section (page type) as defined in SECTIONS config
 * @return {String} section name
 */
function getActiveSection() {
    var $body = $('body');
    if ($body.hasClass('webshop-frontpage')) {
        return SECTIONS.FRONTPAGE;
    } else if ($body.hasClass('webshop-search')) {
        return SECTIONS.SEARCH;
    } else if ($body.hasClass('webshop-productlist')) {
        return SECTIONS.PRODUCT_LIST;
    } else if ($body.hasClass('webshop-productinfo')) {
        return SECTIONS.PRODUCT_PAGE;
    } else if ($body.hasClass('webshop-showbasket')) {
        return SECTIONS.BASKET;
    } else if ($body.hasClass('webshop-orderstep1')) {
        return SECTIONS.DETAILS;
    } else if ($body.hasClass('webshop-orderstep2')) {
        return SECTIONS.SHIPMENT_PAYMENT;
    } else if ($body.hasClass('webshop-orderstep3')) {
        return SECTIONS.APPROVE;
    } else if ($body.hasClass('webshop-orderstep4')) {
        return SECTIONS.CONFIRMATION;
    } else if ($body.hasClass('webshop-externalpage')) {
        return SECTIONS.EXTERNAL;
    } else if ($body.hasClass('webshop-creditcard')) {
        return SECTIONS.CREDIT_CARD;
    } else if ($body.hasClass('webshop-b2blogin')) {
        return SECTIONS.B2B;
    }
}

function _executeFunctions(event) {
    var section = getActiveSection();
    var sectionFunctions = functions[event][section];
    var globalFunctions = functions[event][SECTIONS.GLOBAL];
    var allFunctions = globalFunctions.concat(sectionFunctions);
    for (var i = 0; i < allFunctions.length; i++) {
        allFunctions[i]();
    }
}

function _init() {
    functions = {};
    Object.keys(SECTIONS).forEach(function(sectionKey) {
        var section = SECTIONS[sectionKey];
        Object.keys(EVENTS).forEach(function(eventKey) {
            var event = EVENTS[eventKey];
            if (!functions[event]) {
                functions[event] = {};
            }
            functions[event][section] = [];
        });
    });


    /**
     * When page script is loaded it dispatches
     * scriptsLoaded event at wich point it's
     * safe to execute rhytm functions
     */
    document.addEventListener('scriptsLoaded', function() {
        _executeFunctions(EVENTS.LOAD);
    }, false);

    window.addEventListener('beforeunload', function() {
        _executeFunctions(EVENTS.UNLOAD);
    });

}

/**
 * Default export function. Allows us to define callback that get executend upon event on given section
 * @param  {string}   event as defined in EVENTS config, events are triggered either by rhythm init  function or by using provided .fire(event) method.
 * @param  {array|string}   takes one or more sections (page types) that will be targeted upon certain event
 * @param  {Function} callback function to be executed on given section and event
 */
function execute(event, sections, callback) {
    if (!(sections instanceof Array)) {
        sections = [sections];
    }
    for (var i = 0; i < sections.length; i++) {
        var section = sections[i];
        functions[event][section].push(callback);
    }
}

/**
 * Triggers custom events so in turn .execute() method callback that is bind to those events will be executed
 * @param  {String} event as defined in EVENTS config
 */
function fire(event) {
    _executeFunctions(event);
}

// Exports the module
var publicFunctions = execute;
publicFunctions.getActiveSection = getActiveSection;
publicFunctions.SECTIONS = SECTIONS;
publicFunctions.EVENTS = EVENTS;
publicFunctions.fire = fire;

module.exports = (function() {
    if (!functions) {
        _init();
    }
    return publicFunctions;
})();
