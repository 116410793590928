/* global order */
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var currencyParser = require('modules/util/formatters/currency');
var errorReporter = require('modules/util/error');

exports.parseOrderLines = function(context) {
    return context.find('.BasketLine_OrderStep4').map(function(index) {
        var $this = $(this);
        var result = {};

        // Name is always inside a link
        result.name = $this.find('td a').text().trim();
        // magic position of product id in table on desktop screens
        result.number = $this.find('td:nth-child(3)').text().trim();

        // on mobile, id is wrongly parsed as price and doesn't exist in DOM
        if (result.number.indexOf(',') > -1 || !result.number.length) {
            var productsInBasket = $('.js-productInfo').text().trim();
            result.number = _findProductNumber(productsInBasket, index, $this);
        }

        // Quantity and price (first and second to last entry in table row)
        result.quantity = $this.find('td:first-child').text();
        result.price = currencyParser($this.find('td:nth-last-child(2)').text().replace('.', ''));

        return result;
    });
};

/**
 * Filters through the string of concatenated product data and extracts product number
 * @param  {string} string of all products ids, names and prices separated with ';'
 * @param  {number} index of the product for which we are extracting id
 * @return {string} product id
 */
function _findProductNumber(string, index, obj) {
    if (!string) {
        errorReporter.send('[[ProductsInBasket]] contains no data' + obj[0].innerHTML);
        return 'undefined';
    }
    var array = string.split(';');
    // check if string data matches expected formatting (id;name;price)
    if (array.length % 3 !== 0) {
        errorReporter.send('[[ProductsInBasket]] format error, check parser code' + obj[0].innerHTML);
        return 'undefined';
    }
    var result = array[index*3];
    // check that result has a value and it's not price (contains ','') or name (contains whitespace)
    if (result && !result.match(/[\s|,]/)) {
        return result;
    } else {
        errorReporter.send('[[ProductsInBasket]] format error, result not a SKU: ' + result + ' ' + obj[0].innerHTML);
        return 'undefined';
    }
}

/**
 * order is global variable defined under text/buttons > order step 4 code field
 */

exports.parseOrderDetails = function() {
    var result = {};
    result.id = order.id;
    result.total = order.total;
    result.vat = order.total * 0.2;
    return result;
};
