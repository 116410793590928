'use strict';

var loadJS = window.loadJS;

var R = require(29);

var scriptsLoaded = new Event('scriptsLoaded');
window.scriptsLoaded = scriptsLoaded;

/**
 * inject page-specific scripts based on context
*/
function injectScripts() {

    switch (R.getActiveSection()) {
        case R.SECTIONS.FRONTPAGE:
            _loadScript('frontpage');
            _injectBasket();
            break;
        case R.SECTIONS.SEARCH:
        case R.SECTIONS.PRODUCT_LIST:
            _loadScript('product-list');
            _injectBasket();
            break;
        case R.SECTIONS.PRODUCT_PAGE:
            _loadScript('product-page');
            _injectBasket();
            break;
        case R.SECTIONS.BASKET:
        case R.SECTIONS.DETAILS:
        case R.SECTIONS.SHIPMENT_PAYMENT:
        case R.SECTIONS.APPROVE:
        case R.SECTIONS.CONFIRMATION:
        case R.SECTIONS.CREDIT_CARD:
            _loadScript('checkout');
            break;
        case R.SECTIONS.B2B: 
            _loadScript('b2b');
            break;
        default:
            _callback();
            _injectBasket();
            R.fire(R.EVENTS.POST_LOAD);
            return;
    }

}

function _loadScript(section) {
    var path = _getMeta(section);
    console.log(path, section)
    loadJS(path, document.getElementById('loadjs'), function() {
        _callback(section);
    });
}

function _getMeta(metaname) {
    var metas = window.document.getElementsByTagName('meta');
    var meta;
    for (var i = 0; i < metas.length; i++) {
        if (metas[i].name && metas[i].name === metaname) {
            meta = metas[i].content;
            break;
        }
    }
    return meta;
}

function _callback(name) {
    // after additional page script is loaded, dispatch event to trigger execution of rhytm.js
    document.dispatchEvent(scriptsLoaded);
    if (name) {
        console.log(name + ' script has loaded');
    }
}

/**
 * basket.js only needs to load on non-checkout pages and on small screen sizes
 * because it's used for rendering mobile basket
 */
function _injectBasket() {

    var basketLoaded = false;

    // Handle viewport resizes
    $(window).on('debouncedresize', function() {
        if ((window.mq('small') || window.mq('tiny')) && !basketLoaded) {
            loadJS(_getMeta('basket'), document.getElementById('loadjs'));
            basketLoaded = true;
        }

    });
}

module.exports = injectScripts;
