'use strict';

/**
 * This module contains legacy code used to build sticky header on large screens
 * and mobile navigation on smaller screens (main nav. only, for product category nav see fixes.js)
 * it utilizes enquire.js library located under vendors/ dir
 * @TODO: refactor to avoid unnecessary DOM manipulation on mobile
 */


function navigation() {

    function showOffCanvas(type, side) {
        window.scrollTo(0,0);
        $('.right-off-canvas.' + type).addClass('active');
        $('.top-off-canvas.' + type).addClass('active');
        setTimeout(function(){
            $('.off-canvas-wrap').addClass('move-' + side);
            $('html, body, .content').addClass('off-canvas-active ' + type + '-open');
        }, 40);
    }

    function hideOffCanvas() {
        $('.off-canvas-wrap').removeClass('move-left move-down');
        setTimeout(function() {
            $('.off-canvas-active').removeClass('off-canvas-active search-open menu-open basket-open');
            $('.right-off-canvas').removeClass('active');
            $('.top-off-canvas').removeClass('active');
        }, 500);
    }

    // Offcanvas scroll blocker
    $('#blocker').on('touchmove', function(e) {
        e.preventDefault();
        e.stopPropagation();
    });

    // Hide off canvas menu
    $('#blocker').on('click', function(e) {
        if ($('html').is('.off-canvas-active')) {
            e.preventDefault();
            e.stopPropagation();
            hideOffCanvas();
        }
    });

    // Show off canvas menu
    $('.nav-toggle').on('click', function() {
        showOffCanvas('menu', 'left');
    });

    // Show off canvas basket
    $('.basket-toggle').on('click', function() {
        showOffCanvas('basket', 'left');
    });

    // Show off canvas search
    $('.search-toggle').on('click', function() {
        showOffCanvas('search', 'down');
        $('.MobileSearch .SearchField_SearchPage').focus();
    });


    $(document).on('swiperight', function(e) {
        hideOffCanvas();
    });


    var large = 'only screen and (min-width: 60em)';
    var medium = 'only screen and (max-width: 59.375em)';

    // Generating  navigation on large screens
    enquire.register(large, {
        deferSetup: true,
        setup: function() {
            // Activating sticky navigation on large screens
            var didScroll;
            var lastScrollTop = 0;
            var delta = 50;
            var navbarHeight = $('.stickyContainer').outerHeight();
            var wh = $(window).height();
            var dh = $(document).height();
            var latestKnownScrollY = 0;

            var updateHeader = function() {

                var st = latestKnownScrollY;

                // Make sure they scroll more than delta
                if (Math.abs(lastScrollTop - st) <= delta) {
                    return;
                }

                // States
                if (dh > (wh + wh) && st < (lastScrollTop - 20) && st > (navbarHeight + wh)) {
                    // Scroll Down
                    $('.stickyContainer').addClass('fixed').css({
                        'position': 'fixed'
                    });
                } else if (st < 5) {
                    // Scroll Up
                    $('.stickyContainer').removeClass('fixed').css({
                        'position': 'absolute'
                    });
                }
                if (st > wh && st > lastScrollTop && (lastScrollTop - st) < (wh - navbarHeight)) {
                    $('.stickyContainer').addClass('smooth');
                } else {
                    $('.stickyContainer').removeClass('smooth');
                }

                lastScrollTop = st;
            };

            $(window).on('scroll', function() {
                latestKnownScrollY = $(document).scrollTop();
                window.requestAnimationFrame(updateHeader);
            });
        }
    });


    // Generating mobile navigation on medium screens and below
    enquire.register(medium, {

        deferSetup: true,
        setup: function() {
            //Show off canvas menu to calculate correct heights
            $('.right-off-canvas.menu').addClass('active');

            //Store pointer to configureMenu funtion
            var configureMenu = this.configureMenu;
            var showMenu = this.showMenu;

            //Remove un-needed link
            $('#ActiveCategory_A').remove();

            //Copying and preparing menu
            var menu = $('#ProductMenu_List').clone();
            menu.addClass('off-canvas-list active');
            menu.find('ul').addClass('right-submenu');
            menu.prepend('<li class="root"><a href="#">Menu</a></li>');
            menu.append('<li class="service"><a href="/shop/external/pages/da/service/kundeservice.html">Kundeservice</a></li>');
            menu.appendTo('.right-off-canvas.menu');

            //Activating curent menu
            var activeCategory = $('.right-off-canvas.menu li.active');
            var curentMenu;
            if (activeCategory.find('ul').length === 0) {
                curentMenu = activeCategory.parent('ul');
            } else {
                curentMenu = activeCategory.children('ul');
            }
            curentMenu.addClass('move-left active');
            configureMenu(curentMenu.siblings('a'));

            //Activating parent menus
            var parentMenus = curentMenu.parents('ul.right-submenu');
            parentMenus.each(function() {
                var $this = $(this);
                $this.addClass('move-left active');
                configureMenu($this.siblings('a'));
            });

            //Appending mobile search
            var mobileSearch = $('.TopSearch > div:not(.search-toggle)').clone().addClass('MobileSearch');
            mobileSearch.appendTo($('.top-off-canvas.search'));

            // Off canvas menu click
            $('.right-off-canvas.menu a').on('click', function(e) {
                var $this = $(this);
                var parent = $this.parent();

                if (!parent.hasClass('has-dropdown') && !parent.hasClass('back')) {
                    hideOffCanvas();

                // go to lower category
                } else if (parent.hasClass('has-dropdown')) {

                    e.preventDefault();
                    // check if it's lowest category
                    var exist = ($this.siblings('ul').children('.back').length > 0);
                    if (!exist) {
                        configureMenu($this, true);
                    }
                    showMenu($this);

                // go back to parent category
                } else if (parent.hasClass('back')) {
                    e.preventDefault();
                    var $menu = parent.parent();
                    $menu.removeClass('move-left');
                    $menu.parent().siblings().show();
                    setTimeout(function() {
                        $menu.removeClass('active');
                    }, 300);

                }
            });

            //Hides menu
            $('.right-off-canvas.menu').removeClass('active');
        },

        match: function() {
            if ($('body').is('.webshop-subcat')) {
                var heading = $('.SubCats_Heading');
                if (!heading) {
                    $('.CmsPageStyle').append('<div class="SubCats_Heading hide-for-large-up">Underkategorier</div>', $('.SubCats_Categories_Container'));
                    $('.ProductListHeadline').text($('.top-bar .Activ_Productmenu .ProductMenu_MenuItemBold').text());
                }
            }
        },

        unmatch: function() {
            $('.off-canvas-wrap').removeClass('move-left');
            $('.right-off-canvas.active').removeClass('active');
            if ($('body').is('.webshop-subcat')) {
                $('.CmsPageStyle').prepend($('.SubCats_Categories_Container'));
            }
        },

        configureMenu: function(self, lastCategory) {
            var title = self.attr('title');
            var href = self.attr('href');
            var menuList = self.siblings('ul');
            menuList.prepend('<li class="back"><a href="#">' + title + '</a></li>');
            menuList.append('<li><a href="' + href + '">Vis alle varer i ' + title + '</a></li>');
            menuList.append('<li class="service"><a href="/shop/external/pages/da/service/kundeservice.html">Kundeservice</a></li>');
            if (lastCategory) {
                menuList.find('.back a').on('click', function(e) {
                    e.preventDefault();
                    var parent = $(this).parent();
                    var $menu = parent.parent();
                    $menu.removeClass('move-left');
                    $menu.parent().siblings().show();
                    setTimeout(function() {
                        $menu.removeClass('active');
                    }, 300);
                });
            }
        },

        showMenu: function(self) {
            var menuList = self.siblings('ul');
            menuList.addClass('active');
            setTimeout(function() {
                menuList.toggleClass('move-left');
            }, 10);
            setTimeout(function() {
                self.parent().siblings().hide();
            }, 300);
        }

    });

}

module.exports = {
    init: navigation
};
