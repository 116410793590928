'use strict';

exports.getImageQuality = function() {
    var data = $('.js-imgQuality')[0];
    if (!data) {
        return 0;
    }
    return parseInt(data.textContent.trim());
};

exports.isCustomImage = function(image) {

    if (!image) {
        return false;
    }

    function _customRatio(img) {
        if (img.naturalWidth/img.naturalHeight !== 4/3) {
            return true;
        }
        return false;
    }

    var path = image.src.substr(image.src.indexOf('images'));

    // check if manually uploaded
    if (path.split('/').length < 3 || !path.match(window.ProductNumber) || _customRatio(image)) {
        return true;
    } else {
        return false;
    }
};
