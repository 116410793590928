'use strict';

function getCategory(product) {
    var category = product.category;
    if (product.categories && product.categories.length) {
        if (product.categories.length === 1) {
            category = product.categories[0];
        } else {
            category = '';
            for (var i = 0; i < product.categories.length - 1; i++) {
                category += product.categories[i] + '/';
            }
            category += product.categories[product.categories.length - 1];
        }
    }
    return category;
}

module.exports = getCategory;
