'use strict';

// Holidays for years 2021-2025
// Harcoded data as retrived from web service (it's static so we can retrive and save in advance)
// API endpoint for getting all Danish holidays based on {YEAR}:
// GET: http://kayaposoft.com/enrico/json/v1.0/index.php?action=getPublicHolidaysForYear&year={YEAR}&country=dnk

module.exports = {
    "2021": [
        {
            "date": {
                "day": 1,
                "month": 1,
                "year": 2021,
                "dayOfWeek": 5
            },
            "localName": "Nytårsdag",
            "englishName": "New Year's Day"
        },
        {
            "date": {
                "day": 28,
                "month": 3,
                "year": 2021,
                "dayOfWeek": 7
            },
            "localName": "Palmesøndag",
            "englishName": "Palm Sunday"
        },
        {
            "date": {
                "day": 1,
                "month": 4,
                "year": 2021,
                "dayOfWeek": 4
            },
            "localName": "Skærtorsdag",
            "englishName": "Maundy Thursday"
        },
        {
            "date": {
                "day": 2,
                "month": 4,
                "year": 2021,
                "dayOfWeek": 5
            },
            "localName": "Langfredag",
            "englishName": "Good Friday"
        },
        {
            "date": {
                "day": 4,
                "month": 4,
                "year": 2021,
                "dayOfWeek": 7
            },
            "localName": "1. Påskedag",
            "englishName": "Easter Sunday"
        },
        {
            "date": {
                "day": 5,
                "month": 4,
                "year": 2021,
                "dayOfWeek": 1
            },
            "localName": "2. Påskedag",
            "englishName": "Easter Monday"
        },
        {
            "date": {
                "day": 30,
                "month": 4,
                "year": 2021,
                "dayOfWeek": 5
            },
            "localName": "Store Bededag",
            "englishName": "General Prayer Day"
        },
        {
            "date": {
                "day": 13,
                "month": 5,
                "year": 2021,
                "dayOfWeek": 4
            },
            "localName": "Kristi Himmelfartsdag",
            "englishName": "Ascension Day"
        },
        {
            "date": {
                "day": 23,
                "month": 5,
                "year": 2021,
                "dayOfWeek": 7
            },
            "localName": "1. Pinsedag",
            "englishName": "Whit Sunday"
        },
        {
            "date": {
                "day": 24,
                "month": 5,
                "year": 2021,
                "dayOfWeek": 1
            },
            "localName": "2. Pinsedag",
            "englishName": "Whit Monday"
        },
        {
            "date": {
                "day": 5,
                "month": 6,
                "year": 2021,
                "dayOfWeek": 6
            },
            "localName": "Grundlovsdag",
            "englishName": "Constitution Day"
        },
        {
            "date": {
                "day": 25,
                "month": 12,
                "year": 2021,
                "dayOfWeek": 6
            },
            "localName": "1. Juledag",
            "englishName": "Christmas Day"
        },
        {
            "date": {
                "day": 26,
                "month": 12,
                "year": 2021,
                "dayOfWeek": 7
            },
            "localName": "2. Juledag",
            "englishName": "Boxing Day"
        }
    ],
    "2022" : [
        {
            "date": {
                "day": 1,
                "month": 1,
                "year": 2022,
                "dayOfWeek": 6
            },
            "localName": "Nytårsdag",
            "englishName": "New Year's Day"
        },
        {
            "date": {
                "day": 10,
                "month": 4,
                "year": 2022,
                "dayOfWeek": 7
            },
            "localName": "Palmesøndag",
            "englishName": "Palm Sunday"
        },
        {
            "date": {
                "day": 14,
                "month": 4,
                "year": 2022,
                "dayOfWeek": 4
            },
            "localName": "Skærtorsdag",
            "englishName": "Maundy Thursday"
        },
        {
            "date": {
                "day": 15,
                "month": 4,
                "year": 2022,
                "dayOfWeek": 5
            },
            "localName": "Langfredag",
            "englishName": "Good Friday"
        },
        {
            "date": {
                "day": 17,
                "month": 4,
                "year": 2022,
                "dayOfWeek": 7
            },
            "localName": "1. Påskedag",
            "englishName": "Easter Sunday"
        },
        {
            "date": {
                "day": 18,
                "month": 4,
                "year": 2022,
                "dayOfWeek": 1
            },
            "localName": "2. Påskedag",
            "englishName": "Easter Monday"
        },
        {
            "date": {
                "day": 13,
                "month": 5,
                "year": 2022,
                "dayOfWeek": 5
            },
            "localName": "Store Bededag",
            "englishName": "General Prayer Day"
        },
        {
            "date": {
                "day": 26,
                "month": 5,
                "year": 2022,
                "dayOfWeek": 4
            },
            "localName": "Kristi Himmelfartsdag",
            "englishName": "Ascension Day"
        },
        {
            "date": {
                "day": 5,
                "month": 6,
                "year": 2022,
                "dayOfWeek": 7
            },
            "localName": "1. Pinsedag",
            "englishName": "Whit Sunday"
        },
        {
            "date": {
                "day": 5,
                "month": 6,
                "year": 2022,
                "dayOfWeek": 7
            },
            "localName": "Grundlovsdag",
            "englishName": "Constitution Day"
        },
        {
            "date": {
                "day": 6,
                "month": 6,
                "year": 2022,
                "dayOfWeek": 1
            },
            "localName": "2. Pinsedag",
            "englishName": "Whit Monday"
        },
        {
            "date": {
                "day": 25,
                "month": 12,
                "year": 2022,
                "dayOfWeek": 7
            },
            "localName": "1. Juledag",
            "englishName": "Christmas Day"
        },
        {
            "date": {
                "day": 26,
                "month": 12,
                "year": 2022,
                "dayOfWeek": 1
            },
            "localName": "2. Juledag",
            "englishName": "Boxing Day"
        }
    ],
    "2023": [
        {
            "date": {
                "day": 1,
                "month": 1,
                "year": 2023,
                "dayOfWeek": 7
            },
            "localName": "Nytårsdag",
            "englishName": "New Year's Day"
        },
        {
            "date": {
                "day": 2,
                "month": 4,
                "year": 2023,
                "dayOfWeek": 7
            },
            "localName": "Palmesøndag",
            "englishName": "Palm Sunday"
        },
        {
            "date": {
                "day": 6,
                "month": 4,
                "year": 2023,
                "dayOfWeek": 4
            },
            "localName": "Skærtorsdag",
            "englishName": "Maundy Thursday"
        },
        {
            "date": {
                "day": 7,
                "month": 4,
                "year": 2023,
                "dayOfWeek": 5
            },
            "localName": "Langfredag",
            "englishName": "Good Friday"
        },
        {
            "date": {
                "day": 9,
                "month": 4,
                "year": 2023,
                "dayOfWeek": 7
            },
            "localName": "1. Påskedag",
            "englishName": "Easter Sunday"
        },
        {
            "date": {
                "day": 10,
                "month": 4,
                "year": 2023,
                "dayOfWeek": 1
            },
            "localName": "2. Påskedag",
            "englishName": "Easter Monday"
        },
        {
            "date": {
                "day": 5,
                "month": 5,
                "year": 2023,
                "dayOfWeek": 5
            },
            "localName": "Store Bededag",
            "englishName": "General Prayer Day"
        },
        {
            "date": {
                "day": 18,
                "month": 5,
                "year": 2023,
                "dayOfWeek": 4
            },
            "localName": "Kristi Himmelfartsdag",
            "englishName": "Ascension Day"
        },
        {
            "date": {
                "day": 28,
                "month": 5,
                "year": 2023,
                "dayOfWeek": 7
            },
            "localName": "1. Pinsedag",
            "englishName": "Whit Sunday"
        },
        {
            "date": {
                "day": 29,
                "month": 5,
                "year": 2023,
                "dayOfWeek": 1
            },
            "localName": "2. Pinsedag",
            "englishName": "Whit Monday"
        },
        {
            "date": {
                "day": 5,
                "month": 6,
                "year": 2023,
                "dayOfWeek": 1
            },
            "localName": "Grundlovsdag",
            "englishName": "Constitution Day"
        },
        {
            "date": {
                "day": 25,
                "month": 12,
                "year": 2023,
                "dayOfWeek": 1
            },
            "localName": "1. Juledag",
            "englishName": "Christmas Day"
        },
        {
            "date": {
                "day": 26,
                "month": 12,
                "year": 2023,
                "dayOfWeek": 2
            },
            "localName": "2. Juledag",
            "englishName": "Boxing Day"
        }
    ],
    "2024": [
        {
            "date": {
                "day": 1,
                "month": 1,
                "year": 2024,
                "dayOfWeek": 1
            },
            "localName": "Nytårsdag",
            "englishName": "New Year's Day"
        },
        {
            "date": {
                "day": 24,
                "month": 3,
                "year": 2024,
                "dayOfWeek": 7
            },
            "localName": "Palmesøndag",
            "englishName": "Palm Sunday"
        },
        {
            "date": {
                "day": 28,
                "month": 3,
                "year": 2024,
                "dayOfWeek": 4
            },
            "localName": "Skærtorsdag",
            "englishName": "Maundy Thursday"
        },
        {
            "date": {
                "day": 29,
                "month": 3,
                "year": 2024,
                "dayOfWeek": 5
            },
            "localName": "Langfredag",
            "englishName": "Good Friday"
        },
        {
            "date": {
                "day": 31,
                "month": 3,
                "year": 2024,
                "dayOfWeek": 7
            },
            "localName": "1. Påskedag",
            "englishName": "Easter Sunday"
        },
        {
            "date": {
                "day": 1,
                "month": 4,
                "year": 2024,
                "dayOfWeek": 1
            },
            "localName": "2. Påskedag",
            "englishName": "Easter Monday"
        },
        {
            "date": {
                "day": 26,
                "month": 4,
                "year": 2024,
                "dayOfWeek": 5
            },
            "localName": "Store Bededag",
            "englishName": "General Prayer Day"
        },
        {
            "date": {
                "day": 9,
                "month": 5,
                "year": 2024,
                "dayOfWeek": 4
            },
            "localName": "Kristi Himmelfartsdag",
            "englishName": "Ascension Day"
        },
        {
            "date": {
                "day": 19,
                "month": 5,
                "year": 2024,
                "dayOfWeek": 7
            },
            "localName": "1. Pinsedag",
            "englishName": "Whit Sunday"
        },
        {
            "date": {
                "day": 20,
                "month": 5,
                "year": 2024,
                "dayOfWeek": 1
            },
            "localName": "2. Pinsedag",
            "englishName": "Whit Monday"
        },
        {
            "date": {
                "day": 5,
                "month": 6,
                "year": 2024,
                "dayOfWeek": 3
            },
            "localName": "Grundlovsdag",
            "englishName": "Constitution Day"
        },
        {
            "date": {
                "day": 25,
                "month": 12,
                "year": 2024,
                "dayOfWeek": 3
            },
            "localName": "1. Juledag",
            "englishName": "Christmas Day"
        },
        {
            "date": {
                "day": 26,
                "month": 12,
                "year": 2024,
                "dayOfWeek": 4
            },
            "localName": "2. Juledag",
            "englishName": "Boxing Day"
        }
    ],
    "2025": [
        {
            "date": {
                "day": 1,
                "month": 1,
                "year": 2025,
                "dayOfWeek": 3
            },
            "localName": "Nytårsdag",
            "englishName": "New Year's Day"
        },
        {
            "date": {
                "day": 13,
                "month": 4,
                "year": 2025,
                "dayOfWeek": 7
            },
            "localName": "Palmesøndag",
            "englishName": "Palm Sunday"
        },
        {
            "date": {
                "day": 17,
                "month": 4,
                "year": 2025,
                "dayOfWeek": 4
            },
            "localName": "Skærtorsdag",
            "englishName": "Maundy Thursday"
        },
        {
            "date": {
                "day": 18,
                "month": 4,
                "year": 2025,
                "dayOfWeek": 5
            },
            "localName": "Langfredag",
            "englishName": "Good Friday"
        },
        {
            "date": {
                "day": 20,
                "month": 4,
                "year": 2025,
                "dayOfWeek": 7
            },
            "localName": "1. Påskedag",
            "englishName": "Easter Sunday"
        },
        {
            "date": {
                "day": 21,
                "month": 4,
                "year": 2025,
                "dayOfWeek": 1
            },
            "localName": "2. Påskedag",
            "englishName": "Easter Monday"
        },
        {
            "date": {
                "day": 16,
                "month": 5,
                "year": 2025,
                "dayOfWeek": 5
            },
            "localName": "Store Bededag",
            "englishName": "General Prayer Day"
        },
        {
            "date": {
                "day": 29,
                "month": 5,
                "year": 2025,
                "dayOfWeek": 4
            },
            "localName": "Kristi Himmelfartsdag",
            "englishName": "Ascension Day"
        },
        {
            "date": {
                "day": 5,
                "month": 6,
                "year": 2025,
                "dayOfWeek": 4
            },
            "localName": "Grundlovsdag",
            "englishName": "Constitution Day"
        },
        {
            "date": {
                "day": 8,
                "month": 6,
                "year": 2025,
                "dayOfWeek": 7
            },
            "localName": "1. Pinsedag",
            "englishName": "Whit Sunday"
        },
        {
            "date": {
                "day": 9,
                "month": 6,
                "year": 2025,
                "dayOfWeek": 1
            },
            "localName": "2. Pinsedag",
            "englishName": "Whit Monday"
        },
        {
            "date": {
                "day": 25,
                "month": 12,
                "year": 2025,
                "dayOfWeek": 4
            },
            "localName": "1. Juledag",
            "englishName": "Christmas Day"
        },
        {
            "date": {
                "day": 26,
                "month": 12,
                "year": 2025,
                "dayOfWeek": 5
            },
            "localName": "2. Juledag",
            "englishName": "Boxing Day"
        }
    ]
};
