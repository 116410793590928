'use strict';

module.exports = {

    /**
     * For Google Analytics and Google Ads constants see under app/scripts/inline:
     * confirmation.js
     * google-analytics.js
     *
     */

    'cinemagicAPI': (window.CineMagicConfig && window.CineMagicConfig.api === 'prod') ? 'https://cinemagic-api.herokuapp.com' : 'http://localhost:8080',

    cnet: {
        skey: 'adc46b66',
        api_zoneid: '7032fdd242',
        energy_zoneid: '071ed8e830'
    },

    freshdesk: 'eyJ3aWRnZXRfc2l0ZV91cmwiOiJjaW5lbWFnaWMuZnJlc2hkZXNrLmNvbSIsInByb2R1Y3RfaWQiOm51bGwsIm5hbWUiOiJDaW5lTWFnaWMiLCJ3aWRnZXRfZXh0ZXJuYWxfaWQiOm51bGwsIndpZGdldF9pZCI6ImM3N2MyYWY4LTNjYWYtNGJkOS1hNjVkLTc2Y2JkNGMyOWNmNiIsInNob3dfb25fcG9ydGFsIjpmYWxzZSwicG9ydGFsX2xvZ2luX3JlcXVpcmVkIjpmYWxzZSwiaWQiOjEwMDAwMTE4MjUsIm1haW5fd2lkZ2V0Ijp0cnVlLCJmY19pZCI6IjhkMWQ2OWVjOWU2ZWQxZGRhMTM1NWE1YjkxM2QwMTVhIiwic2hvdyI6MSwicmVxdWlyZWQiOjIsImhlbHBkZXNrbmFtZSI6IkNpbmVNYWdpYyIsIm5hbWVfbGFiZWwiOiJOYW1lIiwibWFpbF9sYWJlbCI6IkVtYWlsIiwicGhvbmVfbGFiZWwiOiJQaG9uZSBOdW1iZXIiLCJ0ZXh0ZmllbGRfbGFiZWwiOiJUZXh0ZmllbGQiLCJkcm9wZG93bl9sYWJlbCI6IkRyb3Bkb3duIiwid2VidXJsIjoiY2luZW1hZ2ljLmZyZXNoZGVzay5jb20iLCJub2RldXJsIjoiY2hhdC5mcmVzaGRlc2suY29tIiwiZGVidWciOjEsIm1lIjoiTWUiLCJleHBpcnkiOjAsImVudmlyb25tZW50IjoicHJvZHVjdGlvbiIsImRlZmF1bHRfd2luZG93X29mZnNldCI6MzAsImRlZmF1bHRfbWF4aW1pemVkX3RpdGxlIjoiQ2hhdCBpbiBwcm9ncmVzcyIsImRlZmF1bHRfbWluaW1pemVkX3RpdGxlIjoiTGV0J3MgdGFsayEiLCJkZWZhdWx0X3RleHRfcGxhY2UiOiJZb3VyIE1lc3NhZ2UiLCJkZWZhdWx0X2Nvbm5lY3RpbmdfbXNnIjoiV2FpdGluZyBmb3IgYW4gYWdlbnQiLCJkZWZhdWx0X3dlbGNvbWVfbWVzc2FnZSI6IkhpISBIb3cgY2FuIHdlIGhlbHAgeW91IHRvZGF5PyIsImRlZmF1bHRfd2FpdF9tZXNzYWdlIjoiT25lIG9mIHVzIHdpbGwgYmUgd2l0aCB5b3UgcmlnaHQgYXdheSwgcGxlYXNlIHdhaXQuIiwiZGVmYXVsdF9hZ2VudF9qb2luZWRfbXNnIjoie3thZ2VudF9uYW1lfX0gaGFzIGpvaW5lZCB0aGUgY2hhdCIsImRlZmF1bHRfYWdlbnRfbGVmdF9tc2ciOiJ7e2FnZW50X25hbWV9fSBoYXMgbGVmdCB0aGUgY2hhdCIsImRlZmF1bHRfdGhhbmtfbWVzc2FnZSI6IlRoYW5rIHlvdSBmb3IgY2hhdHRpbmcgd2l0aCB1cy4gSWYgeW91IGhhdmUgYWRkaXRpb25hbCBxdWVzdGlvbnMsIGZlZWwgZnJlZSB0byBwaW5nIHVzISIsImRlZmF1bHRfbm9uX2F2YWlsYWJpbGl0eV9tZXNzYWdlIjoiV2UgYXJlIHNvcnJ5LCBhbGwgb3VyIGFnZW50cyBhcmUgYnVzeS4gUGxlYXNlICMgbGVhdmUgdXMgYSBtZXNzYWdlICMgYW5kIHdlJ2xsIGdldCBiYWNrIHRvIHlvdSByaWdodCBhd2F5LiIsImRlZmF1bHRfcHJlY2hhdF9tZXNzYWdlIjoiV2UgY2FuJ3Qgd2FpdCB0byB0YWxrIHRvIHlvdS4gQnV0IGZpcnN0LCBwbGVhc2UgdGFrZSBhIGNvdXBsZSBvZiBtb21lbnRzIHRvIHRlbGwgdXMgYSBiaXQgYWJvdXQgeW91cnNlbGYuIn0=',

    hotjar: {
        hjid: 317215,
        hjsv: 5
    },

    trustpilot: {
        businessUnitId: '469147610000640005001940',
        publicAPIKey: 'K6wY3QpY6H350lxqbBE4CjZ9GE1KnJiP'
    },

    imgix: 'https://cinemagic.imgix.net'

};
