'use strict';

(typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

var currencyParser = require('modules/util/formatters/currency');

function getItemId(str) {
    return str.substr(str.indexOf('keyid=') + 6, str.length);
}

// Parser for showbasket.asp page that is fetched with Ajax

exports.parseBasket = function(context) {
    return context.find('.ShowBasket_Custom_DIV').map(function() {
        var result = {};
        var items = result.items = [];
        var fees = result.fees = {};

        $(this).find('.BasketProduct').each(function() {
            var $this = $(this);
            var item = {};
            item.number = $this.find('.BasketProductNumber').text();
            item.name = $this.find('.BasketProductLink a').text();
            item.image = $this.find('.ProductImage_ShowBasket').attr('src');
            item.quantity = $this.find('.Amount').text();
            item.price = currencyParser($this.find('.BasketUnitPrice').text());
            item.url = $this.find('.BasketProductLink a').attr('href');
            item.id = getItemId($this.find('.IncButton a').attr('href'));
            //item.categories = [];
            items.push(item);
        });

        fees.delivery = 0;
        fees.payment = 0;
        return result;
    }).get()[0];
};
