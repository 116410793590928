'use strict';

//@TODO: write unit tests for all edge cases

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var moment = require('moment');
var holidaysStatic = require('../../../configs/holidays');
var config = require('../../../configs/delivery-info');

function getStockCount() {
    var stock = $('.js-stockValMain').html();
    return parseInt(stock);
}

function isRemoteWarehouse() {
    var stockCount = getStockCount();
    return stockCount <= 0 ? true : false;
}

function getDeadline() {
    var remote = isRemoteWarehouse();
    return remote ? config.deadline.remote : config.deadline.local;
}

function getDeliveryText(n) {
    var remote = isRemoteWarehouse();
    return remote ? config.deliveryText[n].remote : config.deliveryText[n].local;
}

function showTimer() {
    if (!isTodayWeekend() && !isTodayHoliday()) {
        var deadline = getDeadline();
        return (moment().hour() < deadline) ? true : false;
    }
    return false;
}

function isHoliday(date, month) {
    var holiday = false;
    if (config.holidays.length) {
        config.holidays.forEach(function(obj) {
            if (obj.date.month === parseInt(month)) {
                if (obj.date.day === parseInt(date)) {
                    holiday = true;
                }
            }
        });
    }
    return holiday;
}

function isTodayHoliday() {
    var date = moment().date();
    var month = moment().month() + 1;
    return isHoliday(date, month);
}

function isTodayWeekend() {
    var day = moment().day();
    return isWeekend(day);
}

function isWeekend(day) {
    if (parseInt(day) === 0 || parseInt(day) === 6) {
        return true;
    }
    return false;
}

function skipHolidaysAndWeekends(day) {
    while (isWeekend(day.format('d'))||isHoliday(day.format('D'), day.format('M'))) {
        day = day.add(1, 'd');
    }
    return day;
}

/**
 * Returns next working day, adjusted for weekends and holidays
 * @return {object} day - moment.js object
 */
function getNextWorkingDay() {
    var day = moment();
    day = day.add(1, 'd');
    return skipHolidaysAndWeekends(day);
}

/**
 * Returns day of the delivery based on number of working days required,
 * checks if any of the days are weekends or holidays and adjust accordingly
 * @param  {number} offset - how many days is required for delivery
 * @return {object} day - moment.js object
 */
function getDeliveryDay(offset) {
    var day = moment();
    var original = moment();
    // check if today is weekend or holiday because there is no handling on those days
    day = skipHolidaysAndWeekends(day);
    // if there was holiday or weekend, add up to first working day
    // so offset needs to run one less time
    if (day.format('d') !== original.format('d')) {
        offset = offset - 1;
    }
    for (var i = offset; i > 0; i--) {
        day = day.add(1, 'd');
        day = skipHolidaysAndWeekends(day);
    }
    return day;
}

/*
For passed moment object returns name of that day in Danish
 */
function getWeekdayString(m) {
    var day = m.format('d');
    switch (parseInt(day)) {
        case 0:
            return config.weekdays[0];
        case 1:
            return config.weekdays[1];
        case 2:
            return config.weekdays[2];
        case 3:
            return config.weekdays[3];
        case 4:
            return config.weekdays[4];
        case 5:
            return config.weekdays[5];
        case 6:
            return config.weekdays[6];
        default:
            return '';
    }
}

function getDate(m, format) {
    var _format = format || 'D/M';
    return m.format(_format);
}

function setDelivery(stock) {
    var workDay;
    var d = {};

    if (!config.holidays || !config.holidays.length) {
        initHolidays();
    }

    // if stock > 0 => local warehouse, else remote
    if (stock > 0) {
        // if now < deadline, timer is visible
        if (moment().hour() < config.deadline.local &&
            !isTodayWeekend() && !isTodayHoliday()) {
            var today = moment().format('d');
            workDay = getDeliveryDay(2);
            // next work day is tommorow
            if (parseInt(workDay.format('d')) === (parseInt(today) + 1)) {
                d.tommorow = config.tommorow;
            }
        } else {
            // delivery in two working days
            workDay = getDeliveryDay(3);
        }
    } else {
        // remote warehouse - 3 days delivery
        if (moment().hour() < config.deadline.remote &&
            !isTodayWeekend() && !isTodayHoliday()) {
            workDay = getDeliveryDay(3);
        } else {
            // past cut-off time, add another day
            workDay = getDeliveryDay(4);
        }
    }
    d.day = getWeekdayString(workDay);
    d.date = getDate(workDay);
    return d;
}

function getDelivery(stock){
    var deliveryInfo = setDelivery(stock);
    var daysTilDelivery = getDaysUntilDelivery(deliveryInfo.date);
    deliveryInfo.days = daysTilDelivery;
    return deliveryInfo;
}

function setDeadline() {
    var hour = getDeadline();
    if (showTimer()) {
        return 'kl. ' + hour + ':00 ';
    }
    var deadline = getWeekdayString(getNextWorkingDay()) + ' kl. ' + hour + ':00';
    if (isRemoteWarehouse()) {
        return deadline + '<span class="show-for-small-down">.</span>';
    }
    return deadline;
}

function getTimeRemaining(deadline) {
    var t = deadline.format('x') - new Date();
    var m = moment.duration(t);
    return {
        'total': t,
        'hours': Math.floor( m.as('hours') % 24 ),
        'minutes': Math.floor( m.as('minutes') % 60 ),
        'seconds': Math.floor( m.as('seconds') % 60 )
    };
}

function initializeClock(element, endtime) {
    var clock = document.querySelector(element);
    var hoursSpan = clock.querySelector('.hours');
    var minutesSpan = clock.querySelector('.minutes');
    var secondsSpan = clock.querySelector('.seconds');
    var deadline = moment().set({
        'hour': endtime,
        'minute': 0,
        'second': 0,
        'milisecond': 0
    });

    function updateClock() {

        var t = getTimeRemaining(deadline);

        if (t.total <= 0) {
            clearInterval(timeinterval);
            updateDeliveryInfo();
            return;
        }

        hoursSpan.textContent = ('0' + t.hours).slice(-2);
        minutesSpan.textContent = ('0' + t.minutes).slice(-2);
        secondsSpan.textContent = ('0' + t.seconds).slice(-2);

    }

    updateClock();
    var timeinterval = setInterval(updateClock, 1000);
}

function updateDeliveryInfo() {

    var d = setDelivery(getStockCount());
    var day = d.tommorow || ' på ' + d.day;
    var date = d.tommorow ? '' : ' d.&nbsp;' + d.date;

    if (showTimer()) {
        $('.js-timer').removeClass('u-hidden');
        var deadline = isRemoteWarehouse() ? config.deadline.remote : config.deadline.local;
        initializeClock('.js-timer', deadline);
    } else {
        $('.js-timer').addClass('u-hidden');
    }

    $('.js-deliveryInfo').removeClass('u-hidden');
    $('.js-deadline').html(setDeadline());
    $('.js-copy-1').html(getDeliveryText(1));
    $('.js-copy-2').html(getDeliveryText(2));
    $('.js-day').html(day);
    $('.js-date').html(date);

    $('.js-deliveryInfo').on('click', function(e) {
        ga('send', {
            hitType: 'event',
            eventCategory: 'ShippingInfo',
            eventAction: 'Press',
            eventLabel: getDaysUntilDelivery(d.date)
        });
    });

}

function getDaysUntilDelivery(date) {
    var today = moment();
    var delivery = moment(date, 'DD/M');
    return delivery.diff(today, 'days') + 1;
}

function init() {
    var year = moment().year().toString();
    // use static data by default, fallback on network
    config.holidays = holidaysStatic[year];
    if (config.holidays && config.holidays.length) {
        if (typeof window.customHolidays !== "undefined") {
           config.holidays = config.holidays.concat(window.customHolidays);
        } 
        updateDeliveryInfo();
        return;
    }
    $.getJSON({
        method: 'GET',
        url: 'https://kayaposoft.com/enrico/json/v1.0/?action=getPublicHolidaysForYear&year=' +
            year + '&country=dnk&jsonp=enricoCb',
        dataType: 'jsonp',
        jsonpCallback: 'enricoCb',
        success: function(data) {
            if (typeof window.customHolidays !== "undefined") {
               config.holidays = data.concat(window.customHolidays);
            } else {
                config.holidays = data;
            }
            updateDeliveryInfo();
        },
        error: function(err) {
            console.error(err);
        }
    });
}

function initHolidays() {
    var year = moment().year().toString();
    if (typeof window.customHolidays !== "undefined") {
       config.holidays = holidaysStatic[year].concat(window.customHolidays);
    } else {
        config.holidays = holidaysStatic[year];
    }
}

var test = {};

/* test-code */
test.getStockCount = getStockCount;
test.isRemoteWarehouse = isRemoteWarehouse;
test.getNextWorkingDay = getNextWorkingDay;
test.getWeekdayString = getWeekdayString;
/* end-test-code */

module.exports = {
    init: init,
    getDelivery: getDelivery,
    setDelivery: setDelivery,
    getDaysUntilDelivery: getDaysUntilDelivery,
    test: test
};
