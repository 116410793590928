'use strict';

var Handlebars = require(116);
var currencyParser = require(64);

Handlebars.registerHelper('calculateSum', function(products) {
    var totalPrice = 0;
    if (products) {
        for (var i = products.length - 1; i >= 0; i--) {
            totalPrice = totalPrice + (products[i].quantity * products[i].price);
        }
        return currencyParser(totalPrice);
    }
    return currencyParser(0);
});
