'use strict';

var serviceConfig = require(6);

/**
 * Return variation index based on experiment ID
 * If Optimizely fails to load, return 0 (default variation)
 * @param  {number} id - optimizely experiment ID
 * @return {number} variation index, usually it's 0 for 'Original' and 1 for 'New'
 */
function _getVariationIndex(id) {
    if (window.optimizely &&
        window.optimizely.variationMap) {
        return window.optimizely.variationMap[id];
    }
    return 0;
}

/**
 * Return experiment name based on experiment ID
 * If Optimizely fails to load return null
 * @param  {number} id - optimizely experiment ID
 * @return {string} experiment name as defined in Optimizely settings
 */
function _getExperimentName(id) {
    if (window.optimizely &&
        window.optimizely.data &&
        window.optimizely.data.experiments) {
        return window.optimizely.data.experiments[id].name;
    }
    return null;
}

/**
 * Return variation name based on experiment ID
 * If Optimizely fails to load, return 'Original' (default variation name)
 * @param  {number} id - optimizely experiment ID
 * @return {string} variation name, usually it's 'Original' or 'New' for split tests
 */
function _getVariationName(id) {
    if (window.optimizely &&
        window.optimizely.variationNamesMap) {
        return window.optimizely.variationNamesMap[id];
    }
    return 'Original';
}

/**
 * Return if experiment is currently active on given page
 * @param  {number} id of optimizely experiment
 * @return {Boolean} if experiment is active returns true, else false
 */
function _isExperimentActive(id) {
    if (window.optimizely &&
        window.optimizely.activeExperiments &&
        window.optimizely.activeExperiments.length) {
        var checkNumber = window.optimizely.activeExperiments.includes(id);
        // Experiments forced with query string are listed as strings in array
        var checkString = window.optimizely.activeExperiments.includes(id.toString());
        return (checkString || checkNumber);
    }
    return false;
}

/**
 * Public function used to set and run optimizely tests
 * @param  {number}   id - optimizley experiment id (defined under configs)
 * @param  {number}   dimensionIndex - number of GA dimension (defined under configs)
 * @param  {Function} cb - callback function called if experiment is active,
 * gets passed experiment variation index, experiment name and variation name
 * @param  {Function} errCb - callback called if experiment is not active
 */
function runTest(id, dimensionIndex, cb, errCb) {

    if (!window.optimizely) {
        setTimeout(function() {
            runTest(id, dimensionIndex, cb, errCb);
        }, 50);
        return;
    }

    window.optimizely.push(['activate', id]);
    if (_isExperimentActive(id)) {

        var experiment = _getExperimentName(id);
        var variation = _getVariationName(id);
        var index = _getVariationIndex(id);

        // record experiment in hotjar
        window.hj('ready', function() {
            window.hj('tagRecording', [experiment + ' - ' + variation]);
        });

        // send google analytics dimension
        if (dimensionIndex && (typeof dimensionIndex === 'number')) {
            var dimension = 'dimension' + dimensionIndex;
            var value = experiment + ' - ' + variation;
            ga('set', dimension, value);
        }

        cb(index, experiment, variation);
    } else {
        console.log('Optimizely experiment ' + id + ' not active.');

        window.hj('ready', function() {
            window.hj('tagRecording', ['Optimizely inactive: ' + id]);
        });

        if (errCb) {
            errCb();
        }
    }
}

function init() {
    // dont load on dev
    if (window.optimizely) {
        return;
    }

    // Initilize optimizely config.
    var projectId = serviceConfig.__PROJECT_ID__;
    var scriptTag = document.createElement('script');
    var s = document.getElementsByTagName('script')[0];
    scriptTag.async = true;
    scriptTag.src = 'https://cdn.optimizely.com/js/' + projectId + '.js';
    scriptTag.onload = function() {
        window.optimizely = window.optimizely || [];
    };
    scriptTag.onerror = function() {
        window.optimizely = window.optimizely || [];
        $('.js-trustPilotCheckoutWidget').remove();
    };
    s.parentNode.insertBefore(scriptTag, s);

}

module.exports = {
    runTest: runTest,
    init: init
};
