'use strict';

var moment = require(122);

var START_DATE = '25/11/2021';
var END_DATE = '24/12/2021';

var copy = {
    PERIOD_START: '26. november 2021',
    PERIOD_END: '23. december 2021'
};

// determine if day in inside period
// for which xmas promotion must be displayed
function _isChristmasPromotion() {
    var compareDate = moment();
    // start date is inclusive
    var startDate = moment(START_DATE, 'DD/MM/YYYY');
    // end date is NOT inclusive
    var endDate = moment(END_DATE, 'DD/MM/YYYY');

    return compareDate.isBetween(startDate, endDate);
}

// append next year if it's november or december
// and current year if it's january
function _appendYear(el) {
    var month = moment().month();
    var year = month > 0 ? moment().year() + 1 : moment().year();
    var yearContainer = el.find('.js-nextYear');
    yearContainer.html(year);
}

function productPageBanner() {

    if (!_isChristmasPromotion()) {
        return;
    }

    var container = $('.ChristmasReturn');


    // Christmas extended return policy
    // @TODO: extract copy in data and markup into template
    container.append(
        '<h3>' +
            '<span class="u-relative">' +
                'Byt dine julegaver frem til d. 3. januar <i aria-hidden="true" class="icon-info"></i>' +
            '</span>' +
        '</h3>' +
        '<div class="ChristmasReturnText collapsed">' +
            '<div class="row">' +
                '<div class="column small-12 medium-6">' +
                    '<p>I forbindelse med julehandlen tilbyder vi udvidet fortrydelsesret.</p>' +
                    '<p>Varer k\u00F8bt i perioden fra den ' + copy.PERIOD_START + ' og frem til den ' + copy.PERIOD_END + ' er d\u00E6kket af en forl\u00E6nget fortrydelsesret, der betyder, at du frem til den 3. januar <span class="js-nextYear"></span> kan give meddelelse om fortrydelse.</p>' +
                    '<p>For at vi kan acceptere fortrydelsen, skal varen returneres i ubrudt emballage.</p>' +
                '</div>' +
                '<div class="column small-12 medium-6">' +
                    '<p>Det er alene fortrydelsesfristen, der forl\u00E6nges. I \u00F8vrigt g\u00E6lder de generelle betingelser for fortrydelsesretten. Se dem her: <a href="/shop/external/pages/da/service/rma.html" title="Læs betingelser for reklamation">Reklamation og fortrydelse</a></p>' +
                    '<p><i>Den udvidede fortrydelsesret g\u00E6lder kun ved private k\u00F8b.</i></p>' +
                '</div>' +
            '</div>' +
        '</div>'
    );

    container.find('h3').on('click', function() {
        var christmasreturntextHeight = $('.ChristmasReturnText > div').outerHeight();
        $('.ChristmasReturnText').css('max-height', christmasreturntextHeight).toggleClass('collapsed');
    });

    _appendYear(container);
    container.removeClass('u-hidden');

}

/**
 * Display additional value prop in header on checkout pages
 * markup is in BasketValueProps.js
 */
function checkoutBanner() {

    if (!_isChristmasPromotion()) {
        return;
    }

    var banner = $('.js-christmasProp');
    _appendYear(banner);
    banner.removeClass('u-hidden');

}

module.exports = {
    productPageBanner: productPageBanner,
    checkoutBanner: checkoutBanner
};
