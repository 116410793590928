'use strict';

var o = require(6);
var g = require(4);
var config = require(2);
var deliveryInfo = require(12);
var optimizely = require(81);

/**
 * Legacy script - query all stock info (on tiles and product page)
 * and adjust stock information and delivery information if it's product tile
 */

function updateStockInfoItem(index) {
    var $this = $(this);
    var productStockNode = $this.find('.StockVal');
    var productStock = parseInt(productStockNode.html());
    var d = deliveryInfo.setDelivery(productStock);

    // modify delivery information on tile
    var deliveryString = '';
    if (productStock > 0) {
        if (d.tommorow) {
            deliveryString = 'Leveres <b>' + config.tommorow + '</b>';
        } else {
            deliveryString = 'Leveres på <b>' + d.day + ' (d.&nbsp;' + d.date + ')</b>';
        }
    } else {
        deliveryString = config.deliveryText[2].remote + ' på <b>' + d.day + ' (d.&nbsp;' + d.date + ')</b>';
    }
    $this.find('.js-deliveryTime').html(deliveryString);

    //Update stock info
    var stockString = '';
    if (productStock > 9) {
        //If the stock is larger than 9, 10+ is shown instead
        productStockNode.html('10+ ');

    // Check that product is in local stock and that it's low stock (2 or less) and
    // apply this only on product card stock info (is-main)
    } else if (productStock > 0 && productStock <= 2 && $this.hasClass('is-main')) {

        stockString = '<span class="StockVal">Kun ' + productStock + '</span> <span class="u-nowrap">på lager</span>';
        $this.addClass('is-low-stock');
        $this.find('.StockCountText').html(stockString);

    } else if (productStock <= 0) {

        if ($this.hasClass('is-main')) {

            optimizely.runTest(o.REMOTE_STOCK, g[o.REMOTE_STOCK], function(index) {
                if (index > 0) {
                    $this.find('.StockCountText').html('På lager').addClass('u-fw600');
                } else {
                    $this.addClass('u-hidden');
                }
            }, function() {
                $this.addClass('u-hidden');
            });

        } else {
            $this.find('.StockCountText').addClass('u-hidden');
        }
    }
}

function updateStockInfo(prod) {
    var $this = $(prod).find('.StockCount');
    $this.each(updateStockInfoItem)
}

/* test-code */
// export globally for use in react
window.updateStockInfo = updateStockInfo;
/* end-test-code */

module.exports = {
    update: updateStockInfo
};
