'use strict';

// Loading required modules
var R = require(29);
var Promise = require(123);

var getCategory = require(54);

var deliveryInfo = require(12);

// Module variables
var IMPRESSION_LENGTH_LIMIT = 10;
var impressions;
var hitPayloadQueue;
var isAsync = false;


/* ASYNC PROCESSING */

// Persists the hitPayload queue to the session storage
function _persistHitPayloadQueue() {
    sessionStorage.setItem('hitPayloadQueue', JSON.stringify({ 'data': hitPayloadQueue }));
}

// Loads the hitPayload queue from the session storage
function _loadHitPayloadQueue() {
    var persistedHitPayloadQueue = JSON.parse(sessionStorage.getItem('hitPayloadQueue'));
    if (persistedHitPayloadQueue) {
        hitPayloadQueue = persistedHitPayloadQueue.data;
    } else {
        hitPayloadQueue = [];
    }
}

// Submits hitPaloads to Universal Analytics
function _processHitPayload(hitPayload) {
    return new Promise(function(resolve, reject) {
        $.ajax('https://www.google-analytics.com/collect', {
            type: 'POST',
            contentType: 'text/plain',
            data: hitPayload,
            success: function() {
                resolve();
            },
            error: function() {
                reject();
            }
        });
    });
}

// Submits hitPaloads to Universal Analytics
function _processHitPayloadQueue() {
    return Promise.all(hitPayloadQueue.map(function(hitPayload) {
        return _processHitPayload(hitPayload);
    })).then(function() {
        // clear queue if successfully submitted
        hitPayloadQueue = [];
        _persistHitPayloadQueue();
    });
}

// Changes Universal Analytics to store hitPayloads in the session storage rather
// than submitting them to Google
function _setAsyncProcessing() {
    if (!isAsync) {
        ga(function(tracker) {
            tracker.set('sendHitTask', function(model) {
                var hitPayload = model.get('hitPayload');
                hitPayloadQueue.push(hitPayload);
                _persistHitPayloadQueue();
            });
        });
        isAsync = true;
    }
}


/* UNIVERSAL ANALYTICS OBJECTS */

// Generates a productFieldObject based on a product
function _getProductFieldObject(product) {
    var result = {
        'id': product.number,
        'name': product.name,
        'brand': product.manufactor,
        'category': getCategory(product),
        'variant': undefined,
        'price': product.price,
        'quantity': product.quantity,
        'coupon': undefined,
        'position': product.position,
        'dimension1': String(product.stock), // make sure it's string in case it's zero so it doesn't get deleted
        'dimension10': product.imageType,
        'dimension2': product.kspType,
        'dimension11': getStockLocation(product.stock),
        'dimension12': getDeliveryDays(product.stock)
    };
    Object.keys(result).forEach(function(k) {
        if (!result[k]) {
            result[k] = undefined;
        }
    });

    return result;
}

function getStockLocation(stock) {
    return parseInt(stock) > 0 ? 'Local' : 'Remote';
}

function getDeliveryDays(stock) {
    var date = deliveryInfo.setDelivery(parseInt(stock)).date;
    return deliveryInfo.getDaysUntilDelivery(date);
}

// Generates a impressionFieldObject based on a product and a list
function _getImpressionFieldObject(product, list) {
    var result = _getProductFieldObject(product);
    result.list = list;
    return result;
}


function _addProduct(product, customValues) {
    var gaProductData = _getProductFieldObject(product);
    if (customValues) {
        Object.keys(customValues).forEach(function(key) {
            gaProductData[key] = customValues[key];
        });
    }
    ga('ec:addProduct', gaProductData);
}

function _processImpressions() {
    if (impressions && impressions.length) {
        for (var i = 0; i < impressions.length; i++) {
            var impression = impressions[i];
            ga('ec:addImpression', impression);
        }
        ga('send', 'event', 'catalog', 'impression', { 'nonInteraction': 1 });
        impressions = [];
    }
}

function browserReloading() {
    _setAsyncProcessing();
    _processImpressions();
}

function init() {
    _loadHitPayloadQueue();
    return _processHitPayloadQueue()
        .then(function() {
            impressions = [];
        }, function() {
            impressions = [];
        }).then(function() {
            return new Promise(_testOptimizely);
        });
}

function _testOptimizely(resolve) {
    if (!window.optimizely) {
        setTimeout(function() {
            _testOptimizely(resolve);
        }, 50);
        return;
    }
    // optimizely also has setTimeout in runTest() so this
    // is in case timeout cycle started there to avoid race
    setTimeout(resolve, 50);
}

function pageLoaded() {
    for (var i = 0; i < impressions.length; i++) {
        var impression = impressions[i];
        ga('ec:addImpression', impression);
    }
    impressions = [];

    ga('send', {
        hitType: 'pageview',
        location: window.location.toString()
    });

    if (window.performance) {
        // Gets the number of milliseconds since page load
        // (and rounds the result since the value must be an integer).
        var timeSincePageLoad = Math.round(performance.now());

        // Sends the timing hit to Google Analytics.
        ga('send', 'timing', 'page', 'loaded', timeSincePageLoad);
    }

}

function productImpression(product, list) {
    impressions.push(_getImpressionFieldObject(product, list));
    if (impressions.length > IMPRESSION_LENGTH_LIMIT) {
        _processImpressions();
    }
}

function productClick(product, list) {
    browserReloading();
    var gaProductData = _getProductFieldObject(product);
    ga('ec:addProduct', gaProductData);
    ga('ec:setAction', 'click', { 'list': list });
    ga('send', 'event', 'UX', 'click');
}

function productAddedToCart(product, list) {
    browserReloading();
    var gaProductData = _getProductFieldObject(product);
    ga('ec:addProduct', gaProductData);
    ga('ec:setAction', 'add', { 'list': list });
    ga('send', 'event', 'UX', 'click');
    _saveProductToStorage(product, false);
}

function _saveProductToStorage(product, overwrite) {
    if (window.localStorage) {
        var storedProduct = localStorage.getItem(product.number);
        if (overwrite || !storedProduct) {
            localStorage.setItem(product.number, JSON.stringify(product));
        }
    }
}

function productDetails(product) {
    var customMetric = {
        'metric1': product.pfp
    };
    _addProduct(product, customMetric);
    ga('ec:setAction', 'detail');
    ga('send', 'event', 'catalog', 'detail', { 'nonInteraction': true });
    _saveProductToStorage(product, true);
}

function _addAllProductsInBasket(products, recordProfit) {
    for (var i = 0; i < products.length; i++) {
        var product = products[i];
        if (window.localStorage && localStorage[product.number]) {
            var data = JSON.parse(localStorage.getItem(product.number));
            product.categories = data.categories;
            product.category = data.category;
            product.stock = data.stock;
            product.manufactor = data.manufactor;
            product.imageType = data.imageType;
            product.kspType = data.kspType;
            product.position = data.position;
            product.pfp = data.pfp; // product profit
        }
        // Product profit
        var customMetric = {};
        if (recordProfit && product.pfp) {
            var profit = undefined;
            profit = Number(product.pfp) * parseInt(product.quantity);
            profit.toFixed(2);
            customMetric = {
                'metric2': profit
            };
        }
        _addProduct(product, customMetric);
    }
}

function checkoutStep(products, stepDetails) {
    _addAllProductsInBasket(products);
    ga('ec:setAction', 'checkout', stepDetails);
}

function checkoutConfirmation(products, orderDetails) {
    _addAllProductsInBasket(products, true);
    ga('ec:setAction', 'purchase', {
        'id': orderDetails.id,
        'revenue': orderDetails.total,
        'tax': orderDetails.vat
    });
}

module.exports = {
    pageLoaded: pageLoaded,
    productImpression: productImpression,
    productClick: productClick,
    productDetails: productDetails,
    productAddedToCart: productAddedToCart,
    checkoutStep: checkoutStep,
    checkoutConfirmation: checkoutConfirmation,
    browserReloading: browserReloading,
    init: init
};
