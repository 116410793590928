'use strict';

// Returns the header message if it has been set in the global scope.

function _getHeaderMessage() {

    if (typeof window.headerMessage !== "undefined") {
       return window.headerMessage;
    }
    return "";
}

/**
 * Display the header message if the property is set in the global scope
 */
function showMessage() {
    
    var message = _getHeaderMessage();

    if(message.length > 0){
        var container = $('.HeaderMessage');       
        container.append(
            '<div class="c-header-message">' +
                message + 
            '</div>'
        );
        container.removeClass('u-hidden');
         // Ajdust header message on product lists with side navigation
        if ($('body').hasClass('webshop-subcat')) {
            moveHeaderMessage();
        }
    }
}


function moveHeaderMessage() {
    var headerMessage = $(".HeaderMessage");
    headerMessage.detach();

    var container = $(".MainContainer > .row > .column");
    container.prepend(headerMessage);

    var headerHeight = $(".HeaderMessage").outerHeight(true);
    $(".Activ_Productmenu").css({ top: headerHeight + 'px' });

    var hrPosition =  parseInt($(".ProductListTop hr").css("top"));
    $(".ProductListTop hr").css({ top: hrPosition + headerHeight + 'px' });    
}

module.exports = {
    showMessage: showMessage
};
