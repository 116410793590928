'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

/**
 * Parses profit from product page
 */

exports.parseProfit = function(context) {

    // Profit for product - metric1
    var pfp = 'undefined';
    var pfpNode = context.find('.js-pfp');
    var pfpString = pfpNode.text().trim();
    // ensure correct parsing of the price (CMP-577)
    if (pfpString.length) {
        pfp = pfpString.match(/,/) ? pfpString.replace('.','').replace(',','.') : pfpString;
    }
    // remove profit data from DOM after parsing
    pfpNode.remove();

    return pfp;
};
