'use strict';

module.exports = {
    deadline: {
        local: 19,
        remote: 12
    },
    deliveryTime: {
        local: '1-2',
        remote: '3'
    },
    holidays: [],
    weekdays: {
        0: 'søndag',
        1: 'mandag',
        2: 'tirsdag',
        3: 'onsdag',
        4: 'torsdag',
        5: 'fredag',
        6: 'lørdag'
    },
    tommorow: ' i&nbsp;morgen',
    deliveryText: {
        1: {
            local: ' og få',
            remote: ''
        },
        2: {
            local: ' varen leveret',
            remote: ' Forventet levering'
        }
    }
};
