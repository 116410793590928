'use strict';

/**
 * Depending on type passed module return either
 * formatted price as string (ie 9.000,00)
 * or price as integer
 */

function formatString(value) {
    var result = '';
    result = value.replace('.', '');
    result = result.replace(',', '.');
    return parseInt(result);
}

function formatInt(value) {
    var result = value.toFixed(2);
    result = result.replace('.', ',');
    result = result.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
    return result;
}

module.exports = function(request) {
    var type = Object.prototype.toString.call(request);
    if (type === '[object String]') {
        return formatString(request);
    }
    return formatInt(request);
};
