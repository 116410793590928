'use strict';

// configs
var serviceConfig = require(7).cnet;

// helpers
var R = require(29);
var serialize = require(66);

// components
var gallery = require(14);
var KSP = require(17);

var imgUtil = require(67);

/**
 * isPlaceholder - helper function to determine if CNET response contains only placeholder image
 * When CNET serves placholder, response is single image with dimensions 200x150
 * @param  {array}  images array from CNET response
 * @return {Boolean}
 */
function isPlaceholder(images) {
    if (images.length === 1) {
        return images[0].w === 200 && images[0].h === 150;
    }
    return false;
}

/**
 * make request to CNET API and based on response,
 * build image gallery and KSP
 */
function cnetInit(Manufactors, VendorNumber) {

    var args = {
        mf: Manufactors,
        pn: VendorNumber,
        lang: 'da'
        //market: 'DK' // CMP-563 - market prop. causes problems with CNET API
    };

    var URL = 'https://ws.cnetcontent.com/' + serviceConfig.skey + '/api/' + serviceConfig.api_zoneid + '?' + serialize(args);

    $.ajax({
        type: 'GET',
        url: URL,
        dataType: 'jsonp',
        timeout: 2000,
        success: function(response) {
            var filteredImages = [];
            if (!response || !response.data) return;
            var images = response.data['ccs-gallery'] ? response.data['ccs-gallery'].images : [];
            var desc = response.data['ccs-standard-desc'];
            var ksp = (response.data['ccs-ksp-features'] && response.data['ccs-ksp-features'].features) ?
                response.data['ccs-ksp-features'].features.items : null;

            // get image quality for original CMS image
            var cmsImgQuality = imgUtil.getImageQuality();
            // check if CMS image is manually uploaded
            var isCustomImage = imgUtil.isCustomImage($('.js-ImageCanvas img')[0]);
            // check if CNET response contains placeholder image
            var isCNETImagePlaceholder = isPlaceholder(images);

            // if CMS displays only placeholder image and CNET don't,
            // use CNET images regardless of quality
            if (cmsImgQuality < 5 && !isCustomImage && !isCNETImagePlaceholder) {
                images.forEach(function(img, i) {
                    filteredImages.push(img);
                });
            // else check if we got at least 2 images for gallery
            } else if (images && images.length > 1) {
                images.forEach(function(img, i) {
                    // filter out images smaller than 480px width
                    if (img.w >= 480) {
                        filteredImages.push(img);
                    }
                });
            }

            // build KSP
            if (ksp && ksp.length) {
                KSP.buildKsp(ksp);
                $('.js-ksp').removeClass('u-hidden').addClass('has-ksp');
            } else if (desc) {
                KSP.buildProductDesc(desc);
                $('.js-ksp').removeClass('u-hidden').addClass('has-description');
            }

            if (filteredImages.length) {
                gallery.buildGallery(filteredImages);
                $('.js-Gallery').removeClass('u-hidden');
                $('.js-ImageCanvas').addClass('u-hidden');
            } else {
                $('.js-Gallery').addClass('u-hidden');
                $('.js-ImageCanvas').removeClass('u-hidden');
            }

            R.fire(R.EVENTS.PRODUCT_PAGE_LOAD);

        },
        error: function(err, status) {

            $('.js-ImageCanvas').removeClass('u-hidden');

            // Analytics stuff
            R.fire(R.EVENTS.PRODUCT_PAGE_LOAD);

            if (status === 'timeout') {
                ga('send', {
                    hitType: 'event',
                    eventCategory: 'Gallery',
                    eventAction: 'Timeout',
                    nonInteraction: true
                });
            }
            console.error('error:', status, err);
        }
    });

}


function appendEnergyLogo(Manufactors, VendorNumber, $obj) {

    var args = {
        mf: Manufactors,
        pn: VendorNumber,
        lang: 'da'
        //market: 'DK' // CMP-563 - market prop. causes problems with CNET API
    };

    var URL = 'https://ws.cnetcontent.com/' + serviceConfig.skey + '/logoicons/' + serviceConfig.energy_zoneid + '?' + serialize(args)+"&format=json";

    var dataSheetURL = 'https://ws.cnetcontent.com/' + serviceConfig.skey + '/page/' + serviceConfig.energy_zoneid + '/LProductFiche?' + serialize(args)+"&mode=specs";


    $.ajax({
        type: 'GET',
        url: URL,
        dataType: 'jsonp',
        timeout: 2000,
        success: function(response) {

            var errorcode = response.product_data_response.contentResponse.errorCode;
            if(!errorcode){
            
                var obj = response.product_data_response.contentResponse.logos[0].logo;
                var logo = obj.media.images[0].image.url;
                var details = obj.contentUrl;

                var $energyLogo = $obj.find(".js-energyLogo");
                $energyLogo.prepend("<img src='" + logo + "'>");
                $energyLogo.removeClass("hide");

                var $dataSheet = $obj.find(".js-dataSheet");

                $dataSheet.click(function(event) {
                    event.preventDefault();
                    console.log("Open modal");
                    $.get(dataSheetURL, function(data){
                        console.log("Data loaded");
                        $(".c-data-sheet__modal .c-modal__content .c-data-sheet__content").html(data);
                        $(".ccs-logo-product-fiche h3").hide();
                    });
                });

                var logoPosition = $energyLogo.offset()

                logoPosition.top = logoPosition.top-70;
                logoPosition.left = logoPosition.left-245;

                $obj.find(".js-energyLogo img").mouseover(function() {
                    $("body").append('<div class="EnergyLogoContainer" style="top: '+ logoPosition.top +'px; left: '+ logoPosition.left +'px; display: block; visibility: visible; background-position: 0% 270px;"><iframe class="EnergyLogoFrame" src="'+details+'" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="auto" data-logo-hover-url="'+details+'"></iframe></div>');
                  })
                  .mouseout(function() {
                    $(".EnergyLogoContainer").remove();
                  });
              }

        },
        error: function(err, status) {

        }
    });

}

/**
 * Loads CNET widget for displaying rich content and energy rating
 */
function loadWidget(Manufactors, VendorNumber) {

    window.ccs_cc_args = window.ccs_cc_args || [];

    var o = window.ccs_cc_args;

    o.push(['mf', Manufactors]);
    o.push(['pn', VendorNumber]);

    o.push(['lang', 'da']);
    o.push(['market', 'DK']);

    o.push(['_SKey', serviceConfig.skey]);
    o.push(['_ZoneId', serviceConfig.energy_zoneid]);

    window.loadJS('https://cdn.cnetcontent.com/jsc/h.js', document.getElementById('loadjs'), function() {
        // wait for 800ms to give cnet script time to execute then check if energy rating is added to dom
        setTimeout(function() {
            if ($('.js-energyRating').find('table').length) {
                $('.js-energyRating').removeClass('u-hidden');
            }
        }, 800);
    });

}

module.exports = {
    cnetInit: cnetInit,
    loadWidget: loadWidget,
    appendEnergyLogo: appendEnergyLogo
};
