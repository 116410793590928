'use strict';

/**
 * Depending on response from CNET, this module
 * builds key selling points or paragraph with short description
 * below product title
 */

function buildKsp(points) {
    var ksp = document.querySelector('.js-ksp');
    var wrapper = document.createElement('ul');
    var BULLETS = 0;
    points.forEach(function(value, index) {
        // display only 4 KSP with less than 80 characters
        // so it fits design
        if (value.name) {
            if (value.name.length > 80) {
                BULLETS++;
            }
            if (BULLETS < 4) {
                var point = document.createElement('li');
                point.textContent = value.name;
                wrapper.appendChild(point);
                BULLETS++;
            }
        }
    });
    ksp.insertBefore(wrapper, ksp.firstChild);
    bindEventListener();
}

function buildProductDesc(desc) {
    var ksp = document.querySelector('.js-ksp');
    var wrapper = document.createElement('p');
    wrapper.textContent = desc;
    ksp.insertBefore(wrapper, ksp.firstChild);
    bindEventListener();
}

function bindEventListener() {
    $('.js-kspLink').on('click', function(e) {
        ga('send', {
            hitType: 'event',
            eventCategory: 'KSP',
            eventAction: 'Link',
            eventLabel: 'Press'
        });
    });
}

module.exports = {
    buildKsp: buildKsp,
    buildProductDesc: buildProductDesc
};
