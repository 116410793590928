'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var formatter = require('../util/formatters/currency');
var imgUtil = require('../util/image');

/**
 * Parses data from product page
 */

exports.parseProduct = function(context) {
    var result = {};

    // Name and number
    result.name = context.find('.Description_Productinfo[itemprop="name"]')[0].textContent.trim();
    result.number = window.ProductNumber;

    // Price, quanityt and stock
    result.price = formatter(context.find('.Prices_Custom_DIV span').text().trim());
    result.quantity = context.find('.TextInputField_ProductInfo#amount').val();
    result.stock = context.find('.js-stockValMain').text().trim();
    if (!result.stock) {
        result.stock = 0;
    }

    // Manufator
    result.manufactor = context.find('.Manufactors').text().trim();
    if (!result.manufactor) {
        result.manufactor = 'undefined';
    }

    // Categories
    result.categories = [];
    context.find('.BreadCrumb_ProductInfo .BreadCrumbLink').each(function() {
        result.categories.push(context.find(this).text().trim());
    });
    var activeCategory = context.find('.BreadCrumb_ProductInfo .BreadCrumbLink_Active').text().trim();
    result.categories.push(activeCategory);
    result.category = activeCategory;

    // Image type - dimension10
    result.imageType = _getImageType();

    // KSP - dimension2
    result.kspType = _getKSPType();

    return result;
};

function _getImageType() {

    // check if gallery loaded
    var gallery = $('.js-ImageCanvas').hasClass('u-hidden');
    if (gallery) {
        return 'Gallery';
    }

    var image = $('.js-ImageCanvas img')[0];

    // check if image is loaded
    if (image.naturalWidth <= 0) {
        return 'Error';
    }

    // check if manually uploaded
    if (imgUtil.isCustomImage(image)) {
        return 'Custom';
    }

    if (imgUtil.getImageQuality() < 5) {
        return 'Placeholder';
    }

    if (imgUtil.getImageQuality() >= 5 && imgUtil.getImageQuality() < 450) {
        return 'Icon';
    }

    if (imgUtil.getImageQuality() >= 450) {
        if (image.naturalWidth >= 540) {
            return 'Regular';
        }
        return 'Small';
    }

}

function _getKSPType() {

    var ksp = $('.js-ksp');

    if (ksp.hasClass('has-ksp')) {
        return 'KSP';
    } else if (ksp.hasClass('has-description')) {
        return 'Description';
    } else {
        return 'No KSP loaded';
    }

}
