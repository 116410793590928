'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var attachFastClick = require('fastclick');

// helpers
var R = require('./helpers/_rhythm');
var injectScripts = require('./helpers/_inject-scripts');
var smoothScroll = require('./helpers/_smooth-scroll');

// services
var hotjar = require('./services/_hotjar');
var optimizely = require('./services/_optimizely');
var freshdesk = require('./services/_freshdesk');

// components
var navigation = require('./components/_navigation');
var fixes = require('./components/_fixes');
var modal = require('./components/_modal');

// rhytm events for Google Analytics
require('modules/controllers/tracking');

// page-specific scripts all depend on main.js to be loaded and DOM ready
// so this is point at wich is safe to trigger injection.
// After page script is loaded it will triger scriptsLoaded event
// and start to execute functions in rhytm callbacks
$(function() {
    injectScripts();
});

// @TODO test placing freshdesk in POST_LOAD for better performance
R(R.EVENTS.LOAD, R.SECTIONS.GLOBAL, function() {
    attachFastClick(document.body);
    hotjar();
    optimizely.init();
    freshdesk();
    navigation.init();
    fixes.global();
    modal();
    smoothScroll();
    // fail-safe in case EVENTS.POST_LOAD fails to trigger
    setTimeout(function() {
        $('html').addClass('scripts-loaded');
    }, 1000);
});

// runs after page section load tasks complete
// event must be triggered manually on each section/page type
R(R.EVENTS.POST_LOAD, R.SECTIONS.GLOBAL, function() {
    $(window).trigger('debouncedresize');
    $('html').addClass('scripts-loaded');
});

window.deliveryInfo = require('./components/_delivery-info')