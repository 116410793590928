'use strict';

var serviceConfig = require(7);

function loadFreshdesk() {

    if (location.host.indexOf('cinemagic') === -1 || window.freshchat_setting) {
        return;
    }

    window.freshchat_setting = serviceConfig.freshdesk;

    var isSecured = window.location && window.location.protocol === 'https:';

    var fc_CSS = document.createElement('link');
    fc_CSS.rel = 'stylesheet';
    fc_CSS.href = (isSecured ? 'https://d36mpcpuzc4ztk.cloudfront.net' : 'http://assets1.chat.freshdesk.com') + '/css/visitor.css';
    document.getElementsByTagName('head')[0].appendChild(fc_CSS);

    var fc_JS = document.createElement('script');
    fc_JS.src = (isSecured ? 'https://d36mpcpuzc4ztk.cloudfront.net' : 'http://assets.chat.freshdesk.com') + '/js/visitor.js';
    document.getElementsByTagName('head')[0].appendChild(fc_JS);

}

module.exports = loadFreshdesk;
