'use strict';
//require('es5-shim');
var Promise = require(123);

// Backend API's
var BASKET_API = '/shop/basket.asp?';
var BASKET_MODEL_URL = '/shop/showbasket.asp';
var DELIVERY_PAYMENT_URL = '/shop/order2.html';

// Backend functions
var API = {
    REMOVE: 'remove=1',
    INCREASE: 'inc=1',
    DECREASE: 'dec=1'
};

// Module variables
var eventListeners = [];
var data = undefined;

// Loads basket basketParser
var parser = require(53);

// Notifys event listeners on model change
function _notifyEventListeners() {
    for (var i = 0; i < eventListeners.length; i++) {
        var eventListener = eventListeners[i];
        eventListener(data);
    }
}

// Calculates the toal number of items in the basket
function _calculateBasketItemTotal() {
    data.totalItems = 0;
    for (var i = 0; i < data.items.length; i++) {
        data.totalItems += Number(data.items[i].quantity);
    }
}

// Calculates the toal number of items in the basket
function _calculateBasketTotal() {
    data.basketTotal = 0;
    for (var i = 0; i < data.items.length; i++) {
        data.basketTotal += Number(data.items[i].price);
    }
}

// Submits the model change to the shop server
function _submitUpdate(action, item) {
    var url = BASKET_API + action + '&keyid=' + item.id;
    $.ajax({
        type: 'GET',
        url: url
    });
    _calculateBasketItemTotal();
    _notifyEventListeners();
}

// Reads the model from the shop API
function _initializeModel() {
    $.ajax({
        type: 'GET',
        url: BASKET_MODEL_URL,
        success: function(basket) {
            basket = '<div>' + basket + '</div>';
            data = parser.parseBasket($(basket));
            _calculateBasketItemTotal();
            _calculateBasketTotal();
            _notifyEventListeners();
        }
    });
}

// Increases the item with the specified index
function increase(index) {
    var item = data.items[index];
    item.quantity++;
    _submitUpdate(API.INCREASE, item);
}

// Decreases the item with the specified index.
// If the quantity==0, the item is removed
function decrease(index) {
    var item = data.items[index];
    var oldAmount = item.quantity;
    if (oldAmount === 1) {
        exports.remove(index);
    } else {
        item.quantity--;
        _submitUpdate(API.DECREASE, item);
    }
}

// Removes the item with the specified index.
function remove(index) {
    var item = data.items[index];
    data.items.splice(index, 1);
    _submitUpdate(API.REMOVE, item);
}

// Adds event listener for model change
function addEventListener(callback) {
    eventListeners.push(callback);
    if (data) {
        callback(data);
    }
}

function getData() {
    return new Promise(function(resolve, reject) {
        addEventListener(function() {
            resolve(data);
        });
    });
}

// Module public functions
var publicFunctions = {
    increase: increase,
    decrease: decrease,
    remove: remove,
    getData: getData,
    addEventListener: addEventListener
};

// Exports the module
module.exports = (function() {
    if (!data) {
        _initializeModel();
        return publicFunctions;
    }
    return publicFunctions;
})();
