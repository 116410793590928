'use strict';

// settings for adwords remarketing implementation
// google_conversion_value and google_custom_params are added dynamically

var TRACKING_ID = 1071248810;

module.exports = {
    remarketing: {
        google_conversion_id: TRACKING_ID,
        google_remarketing_only: true
    },
    conversions: {
        google_conversion_id: TRACKING_ID,
        google_remarketing_only: false,
        google_conversion_language: 'da',
        google_conversion_format: '1',
        google_conversion_color: 'FFFFFF',
        google_conversion_label: '7ovCCOKoSRCq6-f-Aw',
        google_conversion_currency: 'DKK'
    }
};
