'use strict';

/**
 * Various smaller front-end hacks and workarounds due to DanDomain CMS limitations
 */

function globalFixes() {

    // Handle SVG fallbacks
    if (!Modernizr.svg) {
        var imgs = document.querySelectorAll('img[src$=".svg"]');
        for (var i = 0; i < imgs.length; i++) {
            imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
        }
    }

    // Add placholder to search field (partial comes from backend)
    $('.SearchField_SearchPage').attr('placeholder', 'Indtast søgeord...');

    // Hide basket when size==0
    var BasketTotalProducts = $('.BasketTotalProducts').text();
    if (BasketTotalProducts === 0 || BasketTotalProducts === '0') {
        $('.TopBasket .f-dropdown').addClass('empty');
        if ($('body').hasClass('webshop-showbasket')) {
            $('.BasketContent').hide();
            $('.BasketEmpty').show();
        }
    }

    // Setting correct input modes
    $('.amountWrapper .TextInputField_ProductInfo').attr('inputmode', 'numeric').attr('pattern', '[0-9]*');
    $('input[name=email]').attr('type', 'email');
    $('input[name=email_confirm]').attr('type', 'email');

    // Hide related products title if no products to display
    $('.js-productRelated').hide();
    //if ($('.js-productRelated').length && !$('.js-productRelated .Related_Custom_DIV').children().length) {
        //$('.js-productRelated').hide();
    //} else {
        //$('.js-productRelated').show();
    //}

}

function checkoutFixes() {

    // showbasket - shop more
    if ($('body').hasClass('webshop-showbasket')) {
        $('#shopmore').on('click', function() {
            if (document.referrer.indexOf('showbasket') !== -1) {
                location.href = '/';
            } else {
                location.href = document.referrer;
            }
        });
    }

    // order step 3
    var input = $('input[name="acceptTermsBit"]');
    if (input.length) {
        var lbl = input.parent().find('span');
        lbl.replaceWith('<label class="checkout-label" for="acceptTermsBit">' + lbl.html() + '</label>');
    }

}

function _generateSubCatProductMenu() {
    var subCatMenu = $('#ProductMenu_List li.active').clone();
    subCatMenu.find('.dropdown .dropdown .dropdown').remove();
    subCatMenu.appendTo('#ProductMenuLeft');
    var a = $('.SubCats_BreadCrumb > span').length - 1,
        b = 'niv' + a;
    $('#ProductMenuLeft').addClass(b);
}

function generateBrandsCategoryMenu() {
    var activeItem = $('#ProductMenu_List li.active');
    var brandMenu;
    if (activeItem.hasClass('SubCategory1_Productmenu')) {
        brandMenu = activeItem;
    } else {
        brandMenu = activeItem.parents('.SubCategory1_Productmenu');
    }
    $('#ProductMenuLeft').append('<ul></ul>');
    brandMenu
        .clone()
        .appendTo('#ProductMenuLeft ul')
        .addClass('cm-CategoryBrands-menu');
}

function generateBrandsBreadcrumbs() {
    var bc = $('.js-brands-breadcrumbs');
    if (bc.length) {
        $('.BreadCrumbContainer').append('<div class="SubCats_BreadCrumb">' + bc.html() + '</div>');
        bc.remove();
    }
}

function productListFixes() {

    // Ajdust brands listing layout and add side navigation
    if ($('body').hasClass('webshop-brand-list')) {
        generateBrandsBreadcrumbs();
        // get height of description paragraph and offset product list with padding
        var metaHeight = $('.SubCats_Categories_Container').innerHeight();
        $('.ProductList').css({ paddingTop: metaHeight + 'px' });
        $('.ProductMenuLeft').css({ paddingTop: metaHeight + 'px' });
        generateBrandsCategoryMenu();
    }

    // Update brands landing
    if ($('body').hasClass('webshop-brands')) {
        var PageName = $('.Activ_Productmenu').text().trim();
        $('.ProductMenuLeft').html('<h1 class="cm-CategoryBrandsTitle">' + PageName + '</h1>');
    }

    // Remove whitespace on subcat when there is no pagination
    if ($('.BottomNav a').length === 0) {
        $('.BottomNav').hide();
    }

    // Update product list with subcategory
    if ($('body').hasClass('webshop-subcat') &&
        !$('body').hasClass('webshop-brand-list') &&
        !$('body').hasClass('webshop-brands')) {
        // Add menu on left side
        _generateSubCatProductMenu();
        // Disable link on current category title
        $('.ProductMenuLeft .Activ_Productmenu')
            .removeAttr('title')
            .removeAttr('href')
            .css('cursor', 'default')
            .on('click', function(event){
                event.preventDefault();
            });
    }

    // Add breadcrumbs to search result
    if ($('body').hasClass('webshop-search')) {
        var string = '<span class="SubCats_BreadCrumb">' +
            '<a href="frontpage.html">Forside</a>' +
            '<i class="icon-separator"></i>' +
            '<a class="BreadCrumbLink" href="#">S\u00F8geresultater</a>' +
            '</span>';
        $('.BreadCrumbContainer').append(string);
    }
}

// add product number to product details table (or paragraph) if missing or incorrect
function _checkProductNumber() {

    // make sure product number exists as global variable
    if (!window.ProductNumber) {
        return;
    }

    var context = $('.js-productData');
    var table = context.find('table');

    // check if table element exist
    if (table.length) {
        // check if product number exists as table entry
        if (table.text().indexOf('Varenummer') === -1) {
            table.prepend('<tr><td>Varenummer</td><td>:</td><td>' + window.ProductNumber + '</td></tr>');
        } else {
            // check if value in table matches global variable
            if (table.text().indexOf(window.ProductNumber) === -1) {
                table.find('tr:contains("Varenummer")').remove();
                table.prepend('<tr><td>Varenummer</td><td>:</td><td>' + window.ProductNumber + '</td></tr>');
            }
        }
    } else {
        context.prepend('<p>Varenummer: ' + window.ProductNumber + '</p>');
    }

}

// clear cms image output from unnecessary attributes
function _cmsImage() {
    var canvas = document.querySelector('.js-ImageCanvas');
    var ProductImage = canvas.querySelectorAll('img')[0];
    ProductImage.removeAttribute('style');
    ProductImage.removeAttribute('title');
    ProductImage.removeAttribute('border');
    ProductImage.removeAttribute('onclick');
}

function productPageFixes() {
    // Add "Call for price" when price == 0
    var productPrice = parseInt($('.Prices_Custom_DIV').text());
    if (productPrice === 0) {
        $('.PriceContainer').html('<div class="Prices is-undefined">Ring for pris</div>');
    }

    //Move unit to after input field
    $('.Unit').appendTo('.BuyUnit');

    _checkProductNumber();

    _cmsImage();

    if(!$('.c-no-accessories').text().trim().length){
        $('.c-no-accessories').hide();
    }
}

function b2bFixes() {
    var urlMap = {
        '/shop/b2blogin.html': {
            pageType: 'login',
            breadcrumbTitle: 'Forhandler Login',
            breadcrumbLink: '/shop/b2blogin.html',
        },
        '/shop/addb2b.html': {
            pageType: 'application',
            breadcrumbTitle: 'Bliv Forhandler',
            breadcrumbLink: '/shop/addb2b.html',
        },
        '/shop/addb2b.html&AlertAddCustomer=1': {
            pageType: 'thank_you_page',
            breadcrumbTitle: 'Bliv Forhandler',
            breadcrumbLink: '/shop/addb2b.html',
        }
    }
    // extra link for reset password page
    var extraLink = null;
    if (location.search.indexOf('showForgotPswForm') !== -1) {
        extraLink = { link: '/shop/b2blogin.html?showForgotPswForm=1', title: 'Glemt password' };
    }
    var urlDetails = urlMap[window.location.pathname];
    $('#Content').prepend(
        generateB2bBreadcrumbs(urlDetails.breadcrumbTitle, urlDetails.breadcrumbLink, extraLink)
    );
    insertB2BTexts(urlDetails.pageType);
    if (urlDetails.pageType === 'application') selectB2bApplicationUserType();
    assignProperB2bMessageBg();
}

function generateB2bBreadcrumbs(title, link, extraLink) {
    var links = '<div class="column small-12 show-for-medium-up">' +
                '<table width="100%" border="0" cellpadding="0" cellspacing="0"><tbody><tr>' +
                    '<td class="BreadCrumb_ProductInfo BreadCrumb_Productinfo">' +
                        '<a href="/shop/frontpage.html" class="BreadCrumbLink_Deactive">Forside</a>' +
                        '<img src="/dd-cinemagic/img/icons/separator40.png" alt="">' +
                        '<a class="BreadCrumbLink" href="' +  link + '" itemprop="item">' + title + '</a>';
    if (extraLink) {
        links += '<img src="/dd-cinemagic/img/icons/separator40.png" alt="">' +
            '<a class="BreadCrumbLink" href="' + extraLink.link + '" itemprop="item">' + extraLink.title + '</a>';
    }
    links += '</td></tr></tbody></table></div>';
    return links;
}

function insertB2BTexts(page) {
    var textsMap = {
        application: '<h4>Ansøg om et forhandlerlogin nu og opnå en række fordele:</h4>' +
        '<ul>' +
            '<li><strong>Ingen krav til minimum bestilling</strong> - Du får rabat i forhold til vores i forvejen attraktive priser, selv ved køb af en enkelt vare ad gangen</li>' +
            '<li><strong>Mængderabatter</strong> - Synlige direkte i webshoppen</li>' +
            '<li><strong>Drop-shipping</strong> - Mulighed for levering direkte til dine kunder med din virksomhed som afsender</li>' +
            '<li><strong>Hurtig levering</strong> - Garanteret afsendelse samme dag med GLS ved bestilling inden kl. 15:00</li>' +
            '<li><strong>Integration</strong> - Mulighed for csv/xml integration via http/ftp opdateret hver time</li>' +
            '<li><strong>Prisliste på mail</strong> - Modtag vores opdaterede priser hver morgen</li>' +
        '</ul>'+
        '<p>' + 
            ' Brug os som <strong>din pålidelige leverandør</strong> af <strong>mobiltelefoner, tablets og smartwatches</strong>.' +
            ' Vi <strong>parallelimporterer</strong> vores varer <strong>udenom de officielle importører</strong> via et meget <strong>finmasket europæisk leverandørnetværk</strong>.' +
        '</p><p>' +
            ' Du kommer til at nyde godt af vores <strong>effektive logistik</strong> og markedets <strong>højeste kundetilfredshed</strong>.' +
            ' Vi har <strong>fjernet alt unødvendigt bullshit</strong> og tilbyder dig <strong>attraktive varer</strong> til attraktive priser up-front.' + 
            ' Samarbejd med os hvis du vil øge din indtjening og reducere tidsforbruget i dit indkøbsarbejde. ' + 
        '</p>',
        login: ''
    };
    var text = textsMap[page];
    if (text) $('#Content form').after('<div class="b2b-text">' + text + '</div>')
}

function selectB2bApplicationUserType() {
    var select = $(".OptionSelect_B2Blogin[name='CustTypeID']")
    select.val("1").change()
    select.closest('tr').hide()
}

function assignProperB2bMessageBg() {
    var msg = $('.ErrorText_B2Blogin')
    if (msg.length === 0) return;
    var msgText = msg.text().trim().toLowerCase()
    var successMsgs = [
        'e-mailen er nu afsendt',
        'en besked er blevet sendt',
        'en besked er blevet sendt til din e-mailadresse med instruktioner til, hvordan du nulstiller din adgangskode.'
    ]
    for (var i = 0; i <= successMsgs.length - 1; i++) {
        if (msgText.indexOf(successMsgs[i]) !== -1) {
            msg.addClass('ErrorText_B2Blogin_success');
            break;
        }
    }
}

function productPageB2bFixes() {
    var isB2BLoggedin = $('.Link_Topmenu[href="/shop/b2blogin.html?Logout=1"]').length > 0;
    if (!isB2BLoggedin) {
        $('.o-product-card__prices-list').hide()
    }
}

module.exports = {
    global: globalFixes,
    checkoutFixes: checkoutFixes,
    productListFixes: productListFixes,
    productPageFixes: productPageFixes,
    b2bFixes: b2bFixes,
    productPageB2bFixes: productPageB2bFixes
};
