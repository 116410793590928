'use strict';

exports.send = function(content, mail) {
    var $mail = mail || 'mail@hanslambert.com';
    $.ajax({
        url: 'https://formspree.io/' + $mail,
        method: 'POST',
        data: { message: content },
        dataType: 'json'
    });
};
