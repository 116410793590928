'use strict';

function _getScrollbarWidth() {
    var scrollDiv = document.createElement('div');
    scrollDiv.style.overflowY = 'scroll';

    // needed by ie to trigger hasLayout
    // otherwise clientWidth returns 0
    scrollDiv.style.zoom = '1';
    scrollDiv.textContent = 'foo';

    document.body.appendChild(scrollDiv);
    var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
}

function _setElementWidth(elem) {
    var offset = _getScrollbarWidth();
    elem.style['margin-right'] = offset + 'px';
}

function modal() {

    var modal = $('.js-modal');

    modal.each(function(i) {

        var $this = $(this);
        var modalOpenedWith;
        var closeModal = $this.find('.js-closeModal');
        // find all elements that can be focused and interacted with
        var links = $this.find('a[href]', 'area[href]', 'input:not([disabled])', 'select:not([disabled])', 'textarea:not([disabled])', 'button:not([disabled])', 'iframe', 'object', 'embed', '[contenteditable]', '[tabindex]:not([tabindex^="-"])');

        // modal can be opened by using either link with href pointing to modals id
        // or with element that has data-modal attribute with value of modal id
        var openModal = $('[href="#' + this.id + '"], [data-modal="#' + this.id + '"]');

        // remove modal from wherever is placed in DOM and move to
        // bottom of the body so modal's z-index wins
        $this.detach();
        $this.appendTo('body');

        function _closeModal(e, modal) {
            e.preventDefault();
            $('body').removeClass('modal-open');
            $('body').css({ 'marginRight': '0' });
            modal.removeClass('is-open');
            if (modalOpenedWith) {
                modalOpenedWith.focus();
            }
        }

        function _handleKeyEvents(e) {
            if (e.ctrlKey || e.metaKey || e.altKey) {
                return;
            }
            switch (e.which) {
                case 27: // ESC
                    _closeModal(e, $this);
                    e.preventDefault();
                    break;
                case 9: // TAB
                    if (e.shiftKey) {
                        if (e.target === links[0]) {
                            links[links.length - 1].focus();
                            e.preventDefault();
                        }
                    } else {
                        if (e.target === links[links.length - 1]) {
                            links[0].focus();
                            e.preventDefault();
                        }
                    }
                    break;
            }
        }

        openModal.on('click', function(e) {
            e.preventDefault();
            modalOpenedWith = e.currentTarget;
            // adds margin offset to body to prevent content in the
            // background shifting when scrollbar disappears
            if (document.body.scrollHeight > window.innerHeight) {
                $('body').addClass('modal-open');
                _setElementWidth(document.body);
            }
            $this.addClass('is-open');
            closeModal[0].focus();
            $this.keydown(function(e) {
                _handleKeyEvents(e);
            });
        });

        closeModal.on('click', function(e) {
            _closeModal(e, $this);
        });
    });

}

module.exports = modal;
